import React, { useState } from 'react';
import previewImage from "../assets/images/Dashoard/pernonal.png";
import PersonalForm from '../DashboardComponents/PersonalForm';

export default function AccountSettingstab() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    city: '',
    email: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission logic here
    console.log('Form submitted:', formData);
    // You can handle the form submission logic here, such as making an API call to save the data
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div className="acccheader">
            <h1>Account Settings</h1>
            <h2>Saved Addresses</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          <div className="personalinfo">
            <p>Personal Info</p>
            <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", maxHeight: "fit-content" }} />
            <button>Upload New</button>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12">
          <div className="PersonalInfoform">
            <PersonalForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

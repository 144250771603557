import React from 'react';
import Ordericon from "../assets/images/Dashoard/ordericon.png";

const notifications = Array.from({ length: 5 }, (_, i) => ({
  id: i + 1,
  title: "Order Confirmation",
  message: "We're excited to confirm that your order #1T905GV34 has been successfully placed with PrintGraphi! Expect updates soon."
}));

const NotificationTab = () => (
  <div className="Notificationtab">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Notifications</h1>
          {notifications.map(notification => (
            <div className="OrderConfirmation" key={notification.id}>
              <div className="orderconicon">
                <img src={Ordericon} alt="icon" />
              </div>
              <div className="Orderconcontent">
                <h3>{notification.title}</h3>
                <p>{notification.message}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default NotificationTab;
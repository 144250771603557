import React from 'react';
import Basic from '../assets/images/businesscard/basic.png'
import RoundedCard from '../assets/images/businesscard/rounded.png'
import SquareCard from '../assets/images/businesscard/square.png'
import { Link } from 'react-router-dom';

export const CartRelatedProduct = () => {
  return (
    <>
    <section className="exlporeheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
                <div className='exheading'><h1>Explore our all Business Cards</h1></div>
            </div>
            {/* <div className="col-lg-6">
                <div className="helpimgg"><Link to={'#'}><img src={HelpImg} alt="Basic" /></Link></div>
            </div> */}
          </div>
        </div>
      </section>
    <section className="explo">
    <div className="container">
    <div className="row">
        <div className="explorecard">
        <div className="row seccardrow">
            {[...Array(4)].map((_, index) => (
            <div className="col-md-3 col-6" key={index}>
                <Link to="/MainProduct">
                <img src={index === 2 ? SquareCard : index === 1 ? RoundedCard : Basic} alt={index === 2 ? "Square" : "Basic"} />
                <div className="explorecardtext">
                    <h4>{index === 2 ? "Square Cards" : index === 1 ? "Rounded Cards" : "Basic Cards"}</h4>
                    <p>Many desktop publishing packages and web page editors now use</p>
                    <button>
                    <Link to="#">100 Starts from $17.45</Link>
                    </button>
                </div>
                </Link>
            </div>
            ))}
        </div>
        </div>
    </div>
    </div>
    </section>
    </>
  );
};
import React from 'react'
import { ProductFisrsec } from '../ProductComponent/ProductFisrsec'
import { ProductInfo } from '../ProductComponent/Productinfo'
import { Description } from '../ProductComponent/ProductDescription' 
import { RelatedProducts } from '../ProductComponent/RelatedProduct'
import { ProductReview } from '../ProductComponent/Reviews'

export const MainProduct = () => {
  return (
    <>
    <ProductFisrsec/>
    <ProductInfo/>
    <Description/>
    <RelatedProducts/>
    <ProductReview/>
    </>
  )
}
 
// src/services/apiService.js
import axios from 'axios';

// Create an Axios instance with default configuration
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Using the environment variable for base URL
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Optional: Add request/response interceptors if needed
apiClient.interceptors.request.use(
  (config) => {
    // Modify the request before it's sent, e.g., adding tokens
    // config.headers.Authorization = `Bearer ${yourAuthToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("API error:", error);
    return Promise.reject(error);
  }
);

export default apiClient;

import React from 'react';
import Basic from '../assets/images/businesscard/basic.png'
import RoundedCard from '../assets/images/businesscard/rounded.png'
import SquareCard from '../assets/images/businesscard/square.png'
import StandardImg from '../assets/images/StandardContinue.png'
import Sine from '../assets/images/sine.png'
import { Link } from 'react-router-dom';

export const BusinessCardExploreProduct = () => {
  return (
    <>
<section className="explo">
  <div className="container">
  <div className="row">
    <div className="explorecard">
      <div className="row">
        {[...Array(4)].map((_, index) => (
          <div className="col-md-3 col-6" key={index}>
            <Link to="/MainProduct">
              <img src={index === 2 ? SquareCard : index === 1 ? RoundedCard : Basic} alt={index === 2 ? "Square" : "Basic"} />
              <div className="explorecardtext">
                <h4>{index === 2 ? "Square Cards" : index === 1 ? "Rounded Cards" : "Basic Cards"}</h4>
                <p>Many desktop publishing packages and web page editors now use</p>
                <button>
                  <Link to="#">100 Starts from $17.45</Link>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="row seccardrow">
        {[...Array(4)].map((_, index) => (
          <div className="col-md-3 col-6" key={index}>
            <Link to="/MainProduct">
              <img src={index === 2 ? SquareCard : index === 1 ? RoundedCard : Basic} alt={index === 2 ? "Square" : "Basic"} />
              <div className="explorecardtext">
                <h4>{index === 2 ? "Square Cards" : index === 1 ? "Rounded Cards" : "Basic Cards"}</h4>
                <p>Many desktop publishing packages and web page editors now use</p>
                <button>
                  <Link to="#">100 Starts from $17.45</Link>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
  </div>
</section>
<section className="continue mb-4">
    <div className="row" style={{gap: '10px 0'}}>
        <div className="col-md-6">
            <div className="Standardimgbox">
                <div className="stanImg">
                    <img src={StandardImg} alt="StandardImg" />
                </div>
                <div className="continueteaxt">
                    <h1>Standard Business Cards</h1>
                    <div className="sinearea">
                        <div className='sineimg'>
                            <img src={Sine} alt="Sine" />
                        </div>
                        <div className="addedtocard">
                            <p>Added to cart</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-6">
            <div className="continuebox">
                <Link to="/business-card-cart">
                    <button>Continue</button>
                </Link>
            </div>
        </div>
    </div>
</section>
    </>
  );
};
import React from 'react'
import ForgotPass from '../ForgotPassword/ForgotPass'

export const ForgotPassword = () => {
  return (
    <>
    <ForgotPass/>
    </>
  )
}

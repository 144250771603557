import React, { useState } from "react";
import Dashboardtab from "../DashboardComponents/Dashboardtab";
import RecentProjectstab from "../DashboardComponents/RecentProjectstab";
import MyOrdertab from "../DashboardComponents/MyOrdertab";
import Notificationstab from "../DashboardComponents/Notificationstab";
import AccountSettingstab from "../DashboardComponents/AccountSettingstab";
import PaymentsMethodtab from "../DashboardComponents/PaymentsMethodtab";

const DashboardMain = ({ username, handleLogout }) => {
    const [activeTab, setActiveTab] = useState("dashboard");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Array of tab objects
    const tabs = [
        { id: "dashboard", name: "Dashboard" },
        { id: "projects", name: "Recent Projects" },
        { id: "orders", name: "My Orders" },
        { id: "Notifications", name: "Notifications" },
        { id: "Account", name: "Account Settings" },
        { id: "Payments", name: "Payments methods" },
    ];
    return (
        <section className="Dashboardmain">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-sm-12">
                        <div className="daashboardfirstcol">
                            <ul className="nav nav-tabs">
                                {/* Render tab buttons dynamically */}
                                {tabs.map((tab) => (
                                    <li className="nav-item" key={tab.id}>
                                        <button className={`nav-link ${activeTab === tab.id ? "active" : ""}`} onClick={() => handleTabClick(tab.id)}>
                                            {tab.name}
                                        </button>
                                    </li>
                                ))}
                                <li className="nav-item">
                                    <button onClick={handleLogout}>Logout</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-10 col-sm-12">
                        {/* Render content based on the active tab */}
                        {activeTab === "dashboard" && (
                            <Dashboardtab/>
                        )}
                        {activeTab === "projects" && (
                            <RecentProjectstab/>
                        )}
                        {activeTab === "orders" && (
                            <MyOrdertab/>
                        )}
                        {activeTab === "Notifications" && (
                            <Notificationstab/>
                        )}
                        {activeTab === "Account" && (
                            <AccountSettingstab/>
                        )}
                        {activeTab === "Payments" && (
                           <PaymentsMethodtab/>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default DashboardMain;
import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TopNav } from "./components/HeaderAndFooter/TopNav";
import { FooterMainSection } from "./components/HeaderAndFooter/Footer";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/screens/Home";
import { Businesscards } from "./components/screens/Businesscards";
import { StandardMain } from "./components/screens/StandardMain";
import { MainProduct } from "./components/screens/MainProduct";
import { MainUploaddesign } from "./components/screens/MainUploaddesign";
import { DesignReview } from "./components/screens/DesignReview";
import { Preferences } from "./components/screens/Preferences";
import { Explore } from "./components/screens/Explore";
import { MyCart } from "./components/screens/MyCart";
import { Account } from "./components/screens/Acounts";
import { Shipping } from "./components/screens/Shipping";
import { ShippingMethod } from "./components/screens/ShippingMethod";
import { Payment } from "./components/screens/Payment";
import { ThankYou } from "./components/screens/ThankYou";
import { Dashboard } from "./components/screens/Dashboard";
import Login from "./components/screens/Login";
import Register from "./components/screens/Register";
import OrderPreocess from "./components/screens/OrderPreocess";
import CategoryBusinessCard from "./components/screens/CategoryBusinessCard";
import BusinessCardProduct from "./components/screens/BusinessCardProduct";
import BusinessCardMainProduct from "./components/screens/BusinessCardMainProduct";
import { BusinessCardReviewPage } from "./components/screens/BusinessCardReviewPage";
import { BusinessCardPreference } from "./components/screens/BusinessCardPreference";
import { BusinessCardExplore } from "./components/screens/BusinessCardExplore";
import { BusinessCardMyCart } from "./components/screens/BusinessCardMyCart";
import Otp from "./components/screens/Otp";
import { ChangePass } from "./components/screens/ChangePass";
import { ForgotPassword } from "./components/screens/ForgotPassword";
import OtpResetPassComponent from "./components/OtpComponent/OtpResetPassComponent";
import ChangePasswordForget from "./components/ChangePassword/ChangePasswordForget";
import DashboardMain from "./components/DashboardComponents/DashboardMain";
import { BusinessSub } from "./components/screens/BusinessSub";
function App() {
  return (
    <>
      <TopNav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/MainProduct/:id" element={<MainProduct />} />
        <Route path="/business/:id" element={<BusinessSub />} />
        <Route path="/business-card/:id" element={<Businesscards />} />
        <Route path="/standard-main/:id" element={<StandardMain />} />
        <Route path="/MainUploadDesign/:id" element={<MainUploaddesign />} />
        <Route path="/DesignReview" element={<DesignReview />} />
        <Route path="/Preferences" element={<Preferences />} />
        <Route path="/Explore" element={<Explore />} />
        <Route path="/MyCart" element={<MyCart />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/Shipping" element={<Shipping />} />
        <Route path="/ShippingMethod" element={<ShippingMethod />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route path="/order-tracking" element={<OrderPreocess />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/DashboardMain" element={<DashboardMain />} />
        <Route path="/category-card" element={<CategoryBusinessCard />} />
        <Route path="/business-card-product" element={<BusinessCardMainProduct />} />
        <Route path="/business-card-detail" element={<BusinessCardProduct />} />
        <Route path="/business-card-design-review" element={<BusinessCardReviewPage />} />
        <Route path="/business-card-preference" element={<BusinessCardPreference />} />
        <Route path="/business-card-explore" element={<BusinessCardExplore />} />
        <Route path="/business-card-cart" element={<BusinessCardMyCart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/change-password" element={<ChangePass />} />
        <Route path="/change-password-forget/:email" element={<ChangePasswordForget />} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/otp-screen/:email" element={<Otp />} />
        <Route path="/otp-verify-email/:email" element={<OtpResetPassComponent />} />
        <Route path="/signup" element={<Register />} />
      </Routes>
      <FooterMainSection />
    </>
  );
}

export default App;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import DebitImg from "../assets/images/paycard.png";

export const ShippingDetailsPage = () => {
    const [quantity] = useState(1);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        company: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        phone: "",
        defaultShipping: false,
        defaultBilling: false,
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormData({ ...formData, [name]: newValue });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission, for example, send data to backend
        console.log(formData);
    };

    return (
        <div className="shipping-details mb-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-sm-12">
                        <div className="shippincol">
                            <div className="shippinform">
                                <h2>Shipping Details</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} className="form-control" placeholder="First Name" required />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} className="form-control" placeholder="Last Name" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} className="form-control" placeholder="Company" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" id="address1" name="address1" value={formData.address1} onChange={handleChange} className="form-control" placeholder="Address 1" required />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" id="address2" name="address2" value={formData.address2} onChange={handleChange} className="form-control" placeholder="Address 2" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} className="form-control" placeholder="City/Town" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group slect">
                                        <div className="row">
                                            <div className="col">
                                                <select id="state" name="state" value={formData.state} onChange={handleChange} className="form-control" required>
                                                    <option value="" disabled>
                                                        Select State
                                                    </option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    {/* Add more options for other states */}
                                                </select>
                                                <div className="righticon">
                                                    <FaChevronRight />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <input type="text" id="zipCode" name="zipCode" value={formData.zipCode} onChange={handleChange} className="form-control" placeholder="ZIP Code" required />
                                            </div>
                                            <div className="col-md-6 col-12 righticon">
                                                <select id="country" name="country" value={formData.country} onChange={handleChange} className="form-control" required>
                                                    <option value="">Select Country</option>
                                                    <option value="USA">United States</option>
                                                    <option value="Canada">Canada</option>
                                                    {/* Add more options for other countries */}
                                                </select>
                                                <div className="righticon">
                                                    <FaChevronRight />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="form-control" required placeholder="Phone" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="default">
                                        <div className="">
                                            <input type="checkbox" id="defaultShipping" name="defaultShipping" checked={formData.defaultShipping} onChange={handleChange} className="form-check-input" />
                                            <label htmlFor="defaultShipping" className="form-check-label">
                                                Set as default shipping address
                                            </label>
                                        </div>
                                        <div className="">
                                            <input type="checkbox" id="defaultBilling" name="defaultBilling" checked={formData.defaultBilling} onChange={handleChange} className="form-check-input" />
                                            <label htmlFor="defaultBilling" className="form-check-label">
                                                Set as default billing address
                                            </label>
                                        </div>
                                    </div>
                                    <div className="savebtn">
                                        <button type="submit">Save this Address</button>
                                    </div>
                                </form>
                            </div>
                            <div className="method method-sec-div">
                                <Link to="/ShippingMethod">
                                    <button>
                                        Choose Shipping method
                                        <FaChevronRight />
                                    </button>
                                </Link>
                                <Link to="#">
                                    <button>
                                        Payment Method
                                        <FaChevronRight />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <div className="carttotal">
                            <h1>Order Total</h1>
                            <div className="carttotalcontent">
                                <div className="row">
                                    <div className="col-6">
                                        <h2>Subtotal</h2>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>${(quantity * 20).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Shipping</p>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>Free</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Taxes</p>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>${(quantity * 20 * 0.1).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="carttotalcontentbottom">
                                            <h1>Order Total</h1>
                                        </div>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>${(quantity * 20 * 1.1).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="payimg">
                                            <img src={DebitImg} alt="DebitImg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="carttotalfooter">
                                <Link to="/order-process">
                                    <button>Sign In & Checkout</button>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

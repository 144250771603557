import React from 'react'
import { PreferencesBack }  from '../PreferencesComponent/PreferencesBack'
import { PreferencesMainsec } from '../PreferencesComponent/PreferencesMainsec'
import { NeedHelp } from '../PreferencesComponent/NeedHelp'

export const  Preferences = () => {
  return (
    <>
    <PreferencesBack/>
    <PreferencesMainsec/>
    {/* <NeedHelp/> */}
    </>
  )
}

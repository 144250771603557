// DashboardLogin.js
import React, { useState } from 'react';
import RegistrationPage from './RegistrationPage';
import DashboardMain from './DashboardMain'; // Import the DashboardMain component

export const DashboardLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);

  const handleLogin = () => {
    if (username === 'admin' && password === 'password') {
      setIsLoggedIn(true);
    } else {
      alert('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const toggleRegistration = () => {
    setShowRegistration(!showRegistration);
  };

  return (
    <div className="dashboardlogin">
      <div className="container">
        <div className="row justify-content-center loginrow">
          <div className="col-md-12">
            {!isLoggedIn && !showRegistration ? (
              <div>
                <h1>Login</h1>
                <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <button type="submit" className="btn btn-primary">Login</button>
                  <button type="button" className="btn btn-secondary ml-2" onClick={toggleRegistration}>Create Account</button>
                </form>
              </div>
            ) : (
              <div>
                {!isLoggedIn && showRegistration ? (
                  <RegistrationPage setIsRegistered={setShowRegistration} />
                ) : (
                  <DashboardMain username={username} handleLogout={handleLogout} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLogin;

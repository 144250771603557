import React from 'react'
import Main from '../OrderProcessComponent/Main'

const OrderPreocess = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default OrderPreocess

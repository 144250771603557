import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { fetchProductsByCategoryId } from '../../services/categoryService';

export const BusinessSubCat = () => {
  const { pathname } = window.location;
  const id = pathname.split("/").pop(); // Extracting the category ID from the URL
  
  const [subCat, setSubCat] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Fetch product data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch products based on category ID
        const data = await fetchProductsByCategoryId(id);
        console.log("Fetched category products:", data.data.product); // Log the fetched data
        
        setSubCat(data.data.product || []); // Set the data, or an empty array if no product data is available
      } catch (error) {
        console.error("Error fetching category products:", error);
        setSubCat([]); // Set as an empty array if there's an error
      } finally {
        setIsLoading(false); // Stop the loader
      }
    };
    fetchData();
  }, [id]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <section id="main-categories">
      <div className="container">
        <div className="row">
          <h2>Tailored Printing solutions for all your business needs.</h2>
        </div>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={10}
          slidesPerView={6}
          breakpoints={{
            768: { slidesPerView: 5 },
            480: { slidesPerView: 1 }
          }}
        >
          {subCat.length > 0 ? (
            subCat.map((subcategory, index) => (
              <SwiperSlide key={index}>
                <div className="columns large-4">
                  <div className="imgtext">
                    <Link to={`/business-card/${subcategory.id}`}>
                      <div className="columns small-3">
                        <img src={subcategory.product_image} alt={subcategory.name} />
                      </div>
                      <h6>{subcategory.name} <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <p>No subcategories available.</p> // Display a message if no data is available
          )}
        </Swiper>
      </div>
    </section>
  );
};

import React from 'react'
import { BusinessSubCat } from '../BusinessSubomponent/BusinessSubCat'

export const BusinessSub = () => {
  return (
    <>
    <BusinessSubCat/>
    </>
  )
}

import React from 'react'
import { BusinessCards } from '../BusinessCardComponent/Businesscard'

export const Businesscards = () => {
  return (
    <>
    <BusinessCards/>
    </>
  )
}

import React from 'react';
import { DashboardLogin } from '../DashboardComponents/DashboardLogin'



export const Dashboard = () => {
  return (
    <>
    <DashboardLogin/>
    </>
  )
}
import React from "react";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

export const ShippingMethodPage = () => {
    const quantity = 1;

    return (
        <>
            <section className="ShippingMethod">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="shipping-method">
                                <div className="shipping-method-box">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="shippingheading">
                                                <h1>Shipping Details</h1>
                                                <ul>
                                                    <li>John Doe City</li>
                                                    <li>Center St 7 , Lane 6 , House no. 10</li>
                                                    <li>Adams County, Alabama 99344</li>
                                                    <li>United States of America</li>
                                                    <li>808-256-2563</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="shippingedit">
                                                <p>
                                                    <Link to="#">Add New</Link>
                                                </p>
                                                <Link to="#">
                                                    <button>Edit Address</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Deliverymethod">
                                    <h1>Choose delivery method</h1>
                                    <div className="Deliverymethod-box">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="Deliverymethod-box1">
                                                    <div className="delradio">
                                                        <input type="radio" id="standardDelivery" name="deliveryMethod" value="standard" />
                                                        <label htmlFor="standardDelivery">Standard Delivery</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" id="urgentDelivery" name="deliveryMethod" value="urgent" />
                                                        <label htmlFor="urgentDelivery">Urgent Delivery</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="Deliverymethod-box2">
                                                    <p>$56.00</p>
                                                    <p>-$5.00</p>
                                                </div>
                                                <div className="Deliverymethod-box3">
                                                    <Link to="/Payment">
                                                        <button>Choose Shipping Method</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="method">
                                    <Link to="/Payment">
                                        <button>
                                            Choose Shipping method
                                            <FaChevronRight />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="carttotal">
                                <h1>Order Total</h1>
                                <div className="carttotalcontent">
                                    <div className="row">
                                        <div className="col-6">
                                            <h2>Subtotal</h2>
                                        </div>
                                        <div className="col-6 alignleft">
                                            <p>${(quantity * 20).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>Shipping</p>
                                        </div>
                                        <div className="col-6 alignleft">
                                            <p>Free</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>Taxes</p>
                                        </div>
                                        <div className="col-6 alignleft">
                                            <p>${(quantity * 20 * 0.1).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="carttotalcontentbottom">
                                                <h1>Order Total</h1>
                                            </div>
                                        </div>
                                        <div className="col-6 alignleft">
                                            <p>${(quantity * 20 * 1.1).toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

import React from 'react';
import { Link } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const categories = [
  { imgSrc: require('../../../assets/images/categoriesmain/1.png'), text: 'T-Shirts' },
  { imgSrc: require('../../../assets/images/categoriesmain/2.png'), text: 'Booklets' },
  { imgSrc: require('../../../assets/images/categoriesmain/3.png'), text: 'Signposts' },
  { imgSrc: require('../../../assets/images/categoriesmain/4.png'), text: 'Banners' },
  { imgSrc: require('../../../assets/images/categoriesmain/5.png'), text: 'Stickers' },
  { imgSrc: require('../../../assets/images/categoriesmain/6.png'), text: 'Stickers' },
  { imgSrc: require('../../../assets/images/categoriesmain/7.png'), text: 'Stickers' }
];

export const MainCategoriesSection = () => {
    
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section id="main-categories">
      <div className="container">
        <div className="row">
          <h2>Tailored Printing solutions for all your business needs.</h2>
        </div>
        <Slider {...settings}>
          {categories.map((category, index) => (
            <div key={index} className="columns large-4">
              <div className="imgtext">
                <Link to="#">
                    <div className="columns small-3">
                      <img src={category.imgSrc} alt="Alt text goes here" />
                    </div>
                    <h6>{category.text} <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
        <div className="row">
          <h2>Empowering Your Brand With High-Quality Printing Solutions.</h2>
        </div>
        <Slider {...settings}>
          {categories.map((category, index) => (
            <div key={index} className="columns large-4">
              <div className="imgtext">
                <Link to="#">
                    <div className="columns small-3">
                      <img src={category.imgSrc} alt="Alt text goes here" />
                    </div>
                    <h6>{category.text} <i className="fa fa-long-arrow-right" aria-hidden="true"></i></h6>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
import React from 'react'
import { MyCartBack } from '../MyCartComponents/MyCartBack'
import { OrderTotal } from '../MyCartComponents/OrderTotal'
import { CartRelatedProduct } from '../MyCartComponents/CartRelatedProduct'


export const MyCart = () => {
  return (
    <>
    <MyCartBack/>
    <OrderTotal/>
    <CartRelatedProduct/>
    </>
  )
}
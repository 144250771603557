import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Frame from '../assets/images/frame.png';
import Eye from '../assets/images/eye.png';
import sec1 from '../assets/images/rotate.png'
import sec2 from '../assets/images/pencil.png'
import sec3 from '../assets/images/Vector.png'
import sec4 from '../assets/images/img.png'
import sec5 from '../assets/images/text.png'
import { MdEdit } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import ProgressBar from 'react-bootstrap/ProgressBar';
import bleedIcon from "../assets/images/fit-to-bleed.png"
import fitArea from '../assets/images/fit-to-area.png'
import dltIcon from '../assets/images/delete.png'
import Modal from "react-bootstrap/Modal";
import ProductImage1 from "../assets/images/businesscard/business-card-design-temp-1.png"
import PaymantImage from "../assets/images/paycard.png";
import Form from 'react-bootstrap/Form';

function MyVerticallyCenteredModal(props) {
  const [isSlider, setIsSlider] = useState(1);

  // select color
  const [selectedColor, setSelectedColor] = useState('');

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='uploading-sec-modal-main-div'
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body>
        <div className='row'>
          <div className='col-4'>
            <div className='uploading-sec-modal-sec1-div'>
              <div className='mt-3'>
                <h4 className='uploading-sec-modal-sec1-chkbx-frm-h4'>Industry</h4>
                <div>
                  <Form>
                    {['checkbox'].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Accommodation and Food Services`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Arts, Entertainment and Recreation`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Construction`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Educational Services`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Finance and Insurance`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Healthcare and Social Assistance`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                        <Form.Check // prettier-ignore
                          type={type}
                          id={`default-${type}`}
                          label={`Information`}
                          className='uploading-sec-modal-sec1-chkbx-frm'
                        />
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
              <div className='mt-3'>
                <h4 className='uploading-sec-modal-sec1-chkbx-frm-h4'>Select by Cards Textures</h4>
                <ul className='uploading-sec-modal-sec1-chkbx-frm-ul'>
                  <li>Matte</li>
                  <li>Glossy</li>
                  <li>Uncoated</li>
                </ul>
                <span className='uploading-sec-modal-sec1-chkbx-frm-span'>View All Textures</span>
              </div>
              <div className='mt-3'>
                <h4 className='uploading-sec-modal-sec1-chkbx-frm-h4'>Standard Card template</h4>
                <ul className='uploading-sec-modal-sec1-chkbx-frm-ul'>
                  <li>Painted</li>
                  <li>Bamboo</li>
                  <li>Hemp-Blend</li>
                  <li>Raised Foil</li>
                </ul>
                <span className='uploading-sec-modal-sec1-chkbx-frm-span'>View All Textures</span>
              </div>
            </div>
          </div>
          <div className='col-8'>
            <div className="custom-model-wrap uploading-sec-modal-sec-2-div">
              <div className="pop-up-content-wrap">
                <div className="">
                  <h2>Choose template</h2>

                </div>
              </div>
              <div className="horizontalcard">
                <div className="row" style={{ gap: "20px 0" }}>
                  <div className="col-lg-6">
                    <div className="model-card">
                      <Link to="/business-card-design-review">
                        <div className="grey-card">
                          <div className="innerwhite sqaureinnerwhite-0000002">
                            <img src={ProductImage1} alt='temp image' />
                          </div>
                        </div>
                        <div className="model-card-heading">
                          <h3 className='sqaureinnerwhite-0000002-h3'>Business Design</h3>
                          <p className='sqaureinnerwhite-0000002-p'>Many desktop publishing  packages and web page editor now use</p>
                          <span className='sqaureinnerwhite-0000002-span'>100 Start from <h5 className='sqaureinnerwhite-0000002-h5'> $17.45 </h5></span>
                        </div>
                      </Link>
                      <div>
                        <div className="color-selector">
                          <label className={`color-option  ${selectedColor === 'red' ? 'selected' : ''}`} style={{ backgroundColor: "#329ad4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="red"
                              checked={selectedColor === 'red'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'green' ? 'selected' : ''}`} style={{ backgroundColor: "#ffb21e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="green"
                              checked={selectedColor === 'green'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'blue' ? 'selected' : ''}`} style={{ backgroundColor: "#ed1b2e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="blue"
                              checked={selectedColor === 'blue'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'yellow' ? 'selected' : ''}`} style={{ backgroundColor: "#2d51a4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="yellow"
                              checked={selectedColor === 'yellow'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="model-card">
                      <Link to="/business-card-design-review">
                        <div className="grey-card">
                          <div className="innerwhite sqaureinnerwhite-0000002">
                            <img src={ProductImage1} alt='temp image' />
                          </div>
                        </div>
                        <div className="model-card-heading">
                          <h3 className='sqaureinnerwhite-0000002-h3'>Business Design</h3>
                          <p className='sqaureinnerwhite-0000002-p'>Many desktop publishing  packages and web page editor now use</p>
                          <span className='sqaureinnerwhite-0000002-span'>100 Start from <h5 className='sqaureinnerwhite-0000002-h5'> $17.45 </h5></span>
                        </div>
                      </Link>
                      <div>
                        <div className="color-selector">
                          <label className={`color-option  ${selectedColor === 'red' ? 'selected' : ''}`} style={{ backgroundColor: "#329ad4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="red"
                              checked={selectedColor === 'red'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'green' ? 'selected' : ''}`} style={{ backgroundColor: "#ffb21e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="green"
                              checked={selectedColor === 'green'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'blue' ? 'selected' : ''}`} style={{ backgroundColor: "#ed1b2e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="blue"
                              checked={selectedColor === 'blue'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'yellow' ? 'selected' : ''}`} style={{ backgroundColor: "#2d51a4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="yellow"
                              checked={selectedColor === 'yellow'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="model-card">
                      <Link to="/business-card-design-review">
                        <div className="grey-card">
                          <div className="innerwhite sqaureinnerwhite-0000002">
                            <img src={ProductImage1} alt='temp image' />
                          </div>
                        </div>
                        <div className="model-card-heading">
                          <h3 className='sqaureinnerwhite-0000002-h3'>Business Design</h3>
                          <p className='sqaureinnerwhite-0000002-p'>Many desktop publishing  packages and web page editor now use</p>
                          <span className='sqaureinnerwhite-0000002-span'>100 Start from <h5 className='sqaureinnerwhite-0000002-h5'> $17.45 </h5></span>
                        </div>
                      </Link>
                      <div>
                        <div className="color-selector">
                          <label className={`color-option  ${selectedColor === 'red' ? 'selected' : ''}`} style={{ backgroundColor: "#329ad4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="red"
                              checked={selectedColor === 'red'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'green' ? 'selected' : ''}`} style={{ backgroundColor: "#ffb21e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="green"
                              checked={selectedColor === 'green'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'blue' ? 'selected' : ''}`} style={{ backgroundColor: "#ed1b2e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="blue"
                              checked={selectedColor === 'blue'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'yellow' ? 'selected' : ''}`} style={{ backgroundColor: "#2d51a4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="yellow"
                              checked={selectedColor === 'yellow'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="model-card">
                      <Link to="/business-card-design-review">
                        <div className="grey-card">
                          <div className="innerwhite sqaureinnerwhite-0000002">
                            <img src={ProductImage1} alt='temp image' />
                          </div>
                        </div>
                        <div className="model-card-heading">
                          <h3 className='sqaureinnerwhite-0000002-h3'>Business Design</h3>
                          <p className='sqaureinnerwhite-0000002-p'>Many desktop publishing  packages and web page editor now use</p>
                          <span className='sqaureinnerwhite-0000002-span'>100 Start from <h5 className='sqaureinnerwhite-0000002-h5'> $17.45 </h5></span>
                        </div>
                      </Link>
                      <div>
                        <div className="color-selector">
                          <label className={`color-option  ${selectedColor === 'red' ? 'selected' : ''}`} style={{ backgroundColor: "#329ad4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="red"
                              checked={selectedColor === 'red'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'green' ? 'selected' : ''}`} style={{ backgroundColor: "#ffb21e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="green"
                              checked={selectedColor === 'green'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'blue' ? 'selected' : ''}`} style={{ backgroundColor: "#ed1b2e" }}>
                            <input
                              type="radio"
                              name="color"
                              value="blue"
                              checked={selectedColor === 'blue'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                          <label className={`color-option ${selectedColor === 'yellow' ? 'selected' : ''}`} style={{ backgroundColor: "#2d51a4" }}>
                            <input
                              type="radio"
                              name="color"
                              value="yellow"
                              checked={selectedColor === 'yellow'}
                              onChange={handleColorChange}
                              hidden // Hide the actual radio input
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export const UploadingSection = () => {
  const inputFileRef = useRef(null);

  // Initialize state variables
  const [companyName, setCompanyName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [webOther, setWebOther] = useState('');

  // Handle input change for companyName
  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  // Handle input change for businessType
  const handleBusinessTypeChange = (e) => {
    setBusinessType(e.target.value);
  };

  // Handle input change for webOther
  const handleWebOtherChange = (e) => {
    setWebOther(e.target.value);
  };

  //progress bar
  // State to manage the progress value
  const [progress, setProgress] = useState(60); // Initial progress value
  // Function to increase the progress
  const increaseProgress = () => {
    if (progress < 100) {
      setProgress(progress + 10); // Increase progress by 10 (adjust as needed)
    }
  };
  // Function to decrease the progress
  const decreaseProgress = () => {
    if (progress > 0) {
      setProgress(progress - 10); // Decrease progress by 10 (adjust as needed)
    }
  };
  //popup modal



  const [modalShow, setModalShow] = React.useState(false);


    // State to manage the card rotation
    const [isRotated, setIsRotated] = useState(false);

    // Function to toggle rotation
    const toggleRotation = () => {
      setIsRotated(!isRotated);
    };
  

  return (
    <>
      <section className="business-card-detail-h-div">
        <div className="container">
          {/* <div className="row">
          {[1, 2, 3, 4].map((index) => (
            <div className="col-lg-3 col-sm-3" key={index}>
              <Link to="#">
                <div className="editbox">
                  <img src={index === 1 ? Delete : index === 2 ? Rotate : index === 3 ? Fit : Full} alt="" />
                  <p>{index === 1 ? 'Delete' : index === 2 ? 'Rotate 90°' : index === 3 ? 'Fit to bleed' : 'Fit to Area'}</p>
                </div>
              </Link>
            </div>
          ))}
          </div> */}
          <div className="business-card-detail-h">
            <div className="business-card-detail-h-ft">
              <span>T Qt Optimum</span>
              <IoIosArrowDown />
            </div>
            <div className="business-card-detail-h-f">
              <span>-</span>
              <p>12</p>
              <span>+</span>
            </div>
            <div className="business-card-detail-h-tc">
              <Form.Control
                type="color"
                id="exampleColorInput"
                defaultValue="#00000"
                title="Choose your color"
              />
              {/* <span>T</span> */}
            </div>
            <div className="business-card-detail-h-fw">
              <span>B</span>
            </div>
            <div>
              <span>/</span>
            </div>
            <div className="business-card-detail-h-fu">
              <span>U</span>
            </div>
            <div className="business-card-detail-h-xyz">
              <span>
                Your background should reached this line - it prevents white lines along the edges.
              </span>
            </div>
            <div className="business-card-detail-h-arr">
              <span>
                Arrange
              </span>
            </div>
            <div className="business-card-detail-h-dup">
              <span>
                Duplicate
              </span>
            </div>
          </div>
        </div>
      </section>


      <section className="">
        <div className="container">
          <div className="row" style={{gap: "20px 0"}}>
            <div className='col-lg-1 col-3 mt-4'>
              <div className='uploadingarea-sec1-div-edit-sec'>
              <button
                  className='uploadingarea-sec1-div-edit-sec-btn'
                  id='rotateCard'
                  onClick={toggleRotation} // Add the click handler
                >
                  <img src={sec1} />
                </button>
                <button className='uploadingarea-sec1-div-edit-sec-btn uploadingarea-sec1-div-edit-sec-btn-icon-div' id='editCard'>
                  {/* <img src={sec2} /> */}
                  <MdEdit className='uploadingarea-sec1-div-edit-sec-btn-icon' />
                </button>
                <button className='uploadingarea-sec1-div-edit-sec-btn' id='addIcons'>
                  <img src={sec3} />
                </button>
                <button className='uploadingarea-sec1-div-edit-sec-btn' id='addBgImg'>
                  <img src={sec4} />
                </button>
                <button className='uploadingarea-sec1-div-edit-sec-btn' id='addText'>
                  <img src={sec5} />
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-9 mt-4">
              <div className='uploadingarea-main-sec-div'>
                <div className="uploadingarea">
                  <h2 className='uploadingarea-h2-text'>Type Your text</h2>
                  <p className='uploadingarea-p-text'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly</p>
                </div>
                <div className="prewfinishbtn">
                  <div>
                    <form>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control prewfinishbtn-form-input"
                          placeholder="Company Name"
                          aria-describedby="textHelp"
                          value={companyName}
                          onChange={handleCompanyNameChange}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control prewfinishbtn-form-input"
                          placeholder="Business Type"
                          aria-describedby="textHelp"
                          value={businessType}
                          onChange={handleBusinessTypeChange}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control prewfinishbtn-form-input"
                          placeholder="Web Other"
                          aria-describedby="textHelp"
                          value={webOther}
                          onChange={handleWebOtherChange}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="finishbtn">
                    <Link to="">
                      <button
                        className="Click-here"
                        variant="primary"
                        onClick={() => setModalShow(true)}
                      >
                        Next
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* card */}
            <div className="col-lg-3 col-9 mt-4">
              <div className='uploadingarea-main-sec-div'>
                {/* Form content */}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="rendering"
                id='card'
                style={{
                  backgroundImage: `url(${Frame})`,
                  transform: isRotated ? 'rotate(90deg)' : 'rotate(0deg)', // Apply rotation based on state
                  transition: 'transform 0.5s' // Optional: smooth transition for rotation
                }}
              >
                <div className=' card-ipt-dt-m-div-sec'>
                  <div className='card-ipt-dt-m-div-sec-div'>
                    <div className='card-ipt-dt-m-div'>
                      <div className=''>
                        <h3>{companyName || 'Company Name'}</h3>
                      </div>
                      <div className='card-ipt-dt-m-div-b-ln'></div>
                      <div className=''>
                        <h4>{businessType || 'Business Type'}</h4>
                      </div>
                      <div></div>
                    </div>
                    <div className='card-btm-dt-sec-div'>
                      <div>
                        <h4>{webOther || 'Web / Other'}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-2 mt-4'>
              <div className='card-dsgn-sec3-frnt-bck-div'>
                <button className='card-dsgn-sec3-frnt-div'>
                  <div className='card-dsgn-sec3-frnt'></div>
                  <p>Front</p>
                </button>
                <button className='card-dsgn-sec3-frnt-div'>
                  <div className='card-dsgn-sec3-frnt'></div>
                  <p>Back</p>
                </button>
              </div>
            </div>
          </div>

          <div className='uploading-sec-prog-m-div mt-5'>
            <div>
              <button className='uploading-sec-prog-m-div-btn' onClick={decreaseProgress}>-</button>
            </div>
            <div>
              <ProgressBar className='uploading-sec-prog-m-div-main' now={progress} />
            </div>
            <div>
              <button className='uploading-sec-prog-m-div-btn' onClick={increaseProgress}>+</button>
            </div>
          </div>

          <div className='uploading-sec-btn-sec-main'>
            <div className='row' style={{ justifyContent: "center" }}>
              <div className='col-1'>
                <button className='uploadingarea-sec1-btm-div-edit-sec-btn' id='addText'>
                  <img src={dltIcon} />
                  <p>Delete</p>
                </button>
              </div>
              <div className='col-1'>
                <button className='uploadingarea-sec1-btm-div-edit-sec-btn' id='addText'>
                  <img src={sec1} />
                  <p>Rotate 90</p>
                </button>
              </div>
              <div className='col-1'>
                <button className='uploadingarea-sec1-btm-div-edit-sec-btn' id='addText'>
                  <img src={bleedIcon} />
                  <p>Fitt To Bleed</p>
                </button>
              </div>
              <div className='col-1 '>
                <button className='uploadingarea-sec1-btm-div-edit-sec-btn' id='addText'>
                  <img src={fitArea} />
                  <p>Fit To Area</p>
                </button>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};





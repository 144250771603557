import React from 'react';
import { Link } from "react-router-dom";
import newCallImage from '../../../assets/images/newcall.png'; // Import the image

export const NewCallSection = () => {
  return (
    <section id="newcall" style={{ backgroundImage: `url(${newCallImage})` }}>
      <div className="container">
        <div className="row">
          <div className="text-print">
            <h1>Let Print Amazing Things</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content 
              of a page when looking at its layout. The point of using Lorem Ipsum is that it has a 
              more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
              making it look like readable English.</p>
            <Link to="#"><button>Call us Now</button></Link>
          </div>
        </div>
      </div>
    </section>
  );
};


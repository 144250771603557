import React from 'react'
import ChangePassword from '../ChangePassword/ChangePassword'

export const ChangePass = () => {
  return (
    <>
    <ChangePassword/>
    </>
  )
}

import React from 'react'
import { ShippingMethodPage } from '../ShippingMethodComponent/ShippingMethodPage'

export const  ShippingMethod = () => {
  return (
    <>
    <ShippingMethodPage/>
    </>
  )
}

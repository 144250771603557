import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchCategory } from '../../../../services/categoryService';

export const CategoriesSection = () => {
  const [categories, setCategories] = useState([]);

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchCategory();
        const fetchedCategories = response.category;
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section id="categories">
      <div className="container">
        <div className="row">
          <h2>Explore all categories</h2>
        </div>
        <Slider {...settings}>
          {categories.map((category, index) => (
            <div key={index}>
              <div className="imgtext">
                <Link to={`business/${category.id}`}>
                  <div className="row">
                    <div className="columns small-3">
                      <img src={category.category_image} alt={category.title} />
                    </div>
                    <h6>{category.title}</h6>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

import React, { useEffect, useState } from "react";

import SquareCard from "../assets/images/businesscard/square.png";
import { Link } from "react-router-dom";
import axios from "axios";

export const StandardBusinessCard = () => {
    const { pathname } = window.location;
  const id = pathname.split("/").pop(); // Extracting the category ID from the URL
  const [cards, setCards] = useState([]);

    // map function for cards
     // Fetch product data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://printgraphibackend.testingwebsitelink.com/api/user/products`,
          {
            params: { templete_id: id } // Pass the category_id as a query parameter
          }
        );
        
        // Check if response.data.data is an array
        // if (Array.isArray(response.data.data)) {
        //   setSubCat(response.data.data.product);
        // } else {
        //   setSubCat([]); // Set as an empty array if the response is not an array
        // }
        setCards(response.data.data);

        console.log('templete', response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setSubCat([]); // Set as an empty array if there's an error
      }
    };
    fetchData();
  }, [id]);

   

    return (
        <>
            <section className="searchbar">
                <div className="container">
                    <div className="row"  style={{gap: "20px 0"}}>
                        <div className="col-lg-6">
                            <div id="search-bar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                                <input id="search-input" placeholder="Search" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sort">
                                <button>
                                    <Link to="#">Sort</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="prod">
                <div className="container">
                    <div className="row" style={{gap: "20px 0"}}>
                        {cards.map((card, index) => (
                            <div key={index} className="col-lg-3 col-md-4 col-6">
                                <Link to={`/MainProduct/${card.id}`}>
                                    <div className="prod-box">
                                        <img src={card.product_image} alt="square" />
                                        <div className="explorecardtext">
                                            <h4>{card.title}</h4>
                                            <p>{card.description}</p>
                                            <button>
                                                <Link to="/MainProduct">{card.price}</Link>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

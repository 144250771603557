// src/services/productService.js
import apiClient from './apiService';

export const loginUser = async (email, password) => {
  try {
    const response = await apiClient.post('/login', {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error logging in user:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

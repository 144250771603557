import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Google from "../assets/images/google.png";
import Facebook from "../assets/images/facebook.png";
import axios from "axios";

const ChangePassword = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [succShowPopup, setSuccShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    new_password: "",
  });
  let userEmail = localStorage.getItem("email");
  let userToken = localStorage.getItem("userToken");

  // Show error popup with a specific message
  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
    setShowPopup(true);
};
  const handleShow1 = (message) => {
    setSuccessMessage(message);
    setSuccShowPopup(true);

};

  // Close popup
  const handleClose = () => setShowPopup(false);
  const handleClose1 = () => setSuccShowPopup(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {

      // API call to login user
      const response = await axios.post(
        "https://printgraphibackend.testingwebsitelink.com/api/change_password",
        {
          email: userEmail,
          password: formData.password,
          new_password: formData.new_password,
        },
        {
            headers: { Authorization: `Bearer ${userToken}` },
        }
      );
      handleShow1(response.data.message);
      console.log("Login response:", response.data.message);
      window.location.reload();
      // Redirect to home page
    } catch (error) {
      // Display error message in popup
      if (error.response && error.response.data && error.response.data.message) {
        handleShow(error.response.data.message);
      } else {
        handleShow("An error occurred. Please try again.");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="register-bg-img">
        <div className="container">
          <div className="row">
            <div className="col-3"></div>
            <div className="col-lg-6 col-sm-12">
              <div className="accconec login-bg-color">
               
                <div className="account-body">
                  <h5 className="acc-title">Change your password</h5>
                  <form onSubmit={handleLogin}>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Current Password"
                        value={formData.password}
                        onChange={handleChange}
                        name="password"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={formData.new_password}
                        onChange={handleChange}
                        name="new_password"
                        required
                      />
                    </div>
                    <div className="mb-3 form-check">
                      <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="rememberme"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberme"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="forgot-password">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                    </div>
                    <div className="acc-terms">
                      <p>
                        By signing in, you have read and agree to our{" "}
                        <Link to="/terms-and-conditions">
                          Printgraphi General Terms and Conditions
                        </Link>
                      </p>
                    </div>
                    <div className="accbtn">
                      <button type="submit" className="singin">
                        Sign in
                      </button>
                      <Link to="/signup">
                        <button className="makeacc">Make an Account</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>

      {/* Bootstrap modal for error messages */}
      {showPopup && (
        <div
          className="modal fade show error"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Error</h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleClose}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>{errorMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {succShowPopup && (
        <div
          className="modal fade show error"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Success</h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleClose1}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>{successMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose1}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePassword;

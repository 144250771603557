import React from 'react'
import { BusinessCarDesignReviewBack } from '../BusinessCardDesignReview/BusinessCardDesignRivewBack'
import { BusinessCardDesignReviewMain } from '../BusinessCardDesignReview/BusinessCardReviewPage'

export const BusinessCardReviewPage = () => {
  return (
    <>
        <BusinessCardDesignReviewMain />
        <BusinessCarDesignReviewBack />
    </>
  )
}

import React from 'react';
import star from '../assets/images/Star 6.png'

export const ProductReview = () => {
  return (
    <section className="product-review">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="rew">
              <h3>Reviews</h3>
              <p>Last 12 months</p>
            </div>
            <div className="description-accuracy">
              <ul>
                <li>
                  <h4>Description accuracy</h4>
                  <img src="assets/images/rew.png" alt="" />
                </li>
                <li>
                  <h4>Shipping Cost</h4>
                  <img src="assets/images/rew.png" alt="" />
                </li>
                <li>
                  <h4>Delivery Speed</h4>
                  <img src="assets/images/rew.png" alt="" />
                </li>
                <li>
                  <h4>Customer care</h4>
                  <img src="assets/images/rew.png" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="star-rew">
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index}>
                    <img src={star} alt="" />
                  </li>
                ))}
                <li><span>5</span></li>
              </ul>
              <p>Superman@(33). month ago</p>
              <h4>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</h4>
            </div>

            <div className="star-rew">
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index}>
                    <img src={star} alt="" />
                  </li>
                ))}
                <li><span>5</span></li>
              </ul>
              <p>Superman@(33). month ago</p>
              <h4>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</h4>
            </div>

            <div className="star-rew">
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index}>
                    <img src={star} alt="" />
                  </li>
                ))}
                <li><span>5</span></li>
              </ul>
              <p>Superman@(33). month ago</p>
              <h4>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</h4>
            </div>
            
            
            <div className="star-rew">
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index}>
                    <img src={star} alt="" />
                  </li>
                ))}
                <li><span>5</span></li>
              </ul>
              <p>Superman@(33). month ago</p>
              <h4>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</h4>
            </div>

            <div className="star-rew">
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index}>
                    <img src={star} alt="" />
                  </li>
                ))}
                <li><span>5</span></li>
              </ul>
              <p>Superman@(33). month ago</p>
              <h4>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</h4>
            </div>
            {/* Repeat the above div with different data for other reviews */}
          </div>
        </div>
      </div>
    </section>
  );
};
import React from 'react';
import { Link } from "react-router-dom";
import aboutImage from '../../../assets/images/about.png'; // Import the image

export const AboutSection = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-12-medium">
            <div className="text">
              <h1>About <br /> Printgraphi</h1>
              <p>It is a long established fact that a reader will be distracted by the readable content 
                of a page when looking at its layout. The point of using Lorem Ipsum is that it has a 
                more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                making it look like readable English.</p>
              <p>It is a long established fact that a reader will be distracted by the readable content 
                of a page when looking at its layout. The point of using Lorem Ipsum is that it has a 
                more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                making it look like readable English.</p>
              <Link to="#"><button>Learn More</button></Link>
            </div>
          </div>
          <div className="col-6 col-12-medium">
            <img src={aboutImage} alt="About" /> {/* Use the imported image */}
          </div>
        </div>
      </div>
    </section>
  );
};
import React from 'react';
import { Link } from "react-router-dom";
import bannerImage from '../../../assets/images/bannerimg.png'; // Import the banner image
import { Button } from 'react-bootstrap';

export const BannerSection = () => {
    return (
    <section id="banner">
      <div className="container">
        <div className="row align-items-center" style={{gap: "20px 0"}}>
          <div className="col-12 col-md-6">
            <h1>What Can We Print? Absolutely Anything & Everything!</h1>
            <Link to="#"><Button>Get a Quote</Button></Link>
          </div>
          <div className="col-12 col-md-6">
            <img src={bannerImage} alt="Banner" />
          </div>
        </div>
      </div>
    </section>
  );
};
import React, { useEffect, useState } from 'react'
import logo from '../assets/logo/mainlogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { MdDashboard } from 'react-icons/md';
import { IoMdSettings } from 'react-icons/io';
import { TbLogout2 } from 'react-icons/tb';

const Header = ({ children }) => {
  return (
    <header>{children}</header>
  );
};

export const TopNav = () => {
  const navigate = useNavigate();
    
    // State to track whether user is logged in
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
  
    // Check if user is logged in by checking localStorage
    useEffect(() => {
      const userId = localStorage.getItem('userId');
      const userToken = localStorage.getItem('userToken');
      
      if (userId && userToken) {
        setIsLoggedIn(true);
      }
    }, []);
  
    // Logout function
    const handleLogout = () => {
      localStorage.clear();
      setIsLoggedIn(false);
      navigate('/'); // Redirect to login page after logout
      window.location.reload();
    };
  return (
    <Header>
      <section id="top-header">
        <div className="header-top-nav">
          <div className="custom-section">
            <div className="container">
              <div className="row">
                <p style={{ textAlign: 'center' }}>One-Of-A-Kind Fast Eco Luxury Printing + Elevate Your Brand With Endless Possibilities + Free Fast Delivery*</p>
              </div>
            </div>
          </div>
        </div>
        {/* <Navbar collapseOnSelect expand="xl"> */}
          <div className="main-header">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-4 col-md-3">
                  <div className="logo">
                    <Link to="/">
                      <img src={logo} alt="Logo" className='logo-img' />
                    </Link>
                  </div>
                </div>
                <div className="col-8 col-md-6">
                  <form className="d-flex" role="search" style={{ margin: 0 }}>
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </form>
                </div>
                <div className="col-12 col-md-3">
                  <div className="header-icons">
                    <div>
                      <Link to="/mycart">
                        <i className="fa fa-cart-arrow-down"></i>
                      </Link>
                    </div>
                    <span>|</span>
                    <div>
                    {isLoggedIn ? (
                      <div 
                        className="profile-icon-container"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                      >
                        <i className="fa fa-user-circle-o"></i> Profile
                        {showDropdown && (
                          <div className="dropdown-menu headerdropdown">
                            <Link to="/DashboardMain"><MdDashboard/> Dashboard </Link>
                            <Link to="/dashboard"><IoMdSettings /> Settings </Link>
                            <Link to="/change-password">Change Password</Link>
                            <button onClick={handleLogout}>Logout <TbLogout2 /></button>
                          </div>
                        )}
                        <span>|</span>
                      </div>
                    ) : (
                      <Link to="/login">
                        <i className="fa fa-user-circle-o"></i> Login </Link>
                    )}
                    </div>
                    <span>|</span>
                    <div>
                      <select>
                        <option>USA</option>
                        <option>UK</option>
                        <option>AE</option>
                      </select>
                    </div>
                    <span>|</span>
                    <div>
                      <Link to="#">Help</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </Navbar> */}
        <div className="navigation-bars">
          <div className="container">
            <div className="row">
              <div className="col-12 col-12-medium">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/deals">Deals</Link>
                  </li>
                  <li>
                    <Link to="/business-card">Business cards</Link>
                  </li>
                  <li>
                    <Link to="#">Post cards</Link>
                  </li>
                  <li>
                    <Link to="#">Signs & banners</Link>
                  </li>
                  <li>
                    <Link to="#">Clothing & bags</Link>
                  </li>
                  <li>
                    <Link to="#">Promotion Printing</Link>
                  </li>
                  <li>
                    <Link to="#">Booklets</Link>
                  </li>
                  <li>
                    <Link to="#">Books</Link>
                  </li>
                  <li>
                    <Link to="#">Calendars</Link>
                  </li>
                  <li>
                    <Link to="#">Shopping bags</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </section>
    </Header>
  )
}

// src/services/productService.js
import apiClient from './apiService';

// Fetch product details by ID
export const fetchProductById = async (id) => {
  try {
    const response = await apiClient.get(`/product/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error; // Re-throw the error for handling in the calling component
  }
};

// Fetch Cart details by ID
export const fetchCartByIdApi = async (id) => {
  try {
    const userToken = localStorage.getItem('userToken');

    const response = await apiClient.get(`/user_cart/${id}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error; // Re-throw the error for handling in the calling component
  }
};

// Fetch attribute types based on the type parameter
export const fetchAttributesByType = async (type) => {
  try {
    const response = await apiClient.post('/attribute_type', {
       type,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching attribute types:", error);
    throw error;
  }
};

export const fetchProductSizeType = async (type) => {
  try {
    const response = await apiClient.post('/attribute_type_size?type=card', {
       type,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching attribute types:", error);
    throw error;
  }
};

export const addToCart = async (data) => {
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await apiClient.post('/add_to_cart', data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error adding to cart:", error);
    throw error;
  }
};
export const updateAddToCart = async (data, id) => {
  try {
    const userToken = localStorage.getItem('userToken');
    const response = await apiClient.post(`/add_to_cart_update/${id}`, data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching attribute types:", error);
    throw error;
  }
};

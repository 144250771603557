import React, { useState } from 'react';
import StandardImg from '../assets/images/StandardContinue.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import DebitImg from '../assets/images/paycard.png';

export const OrderTotal = () => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <section className="Ordertotal">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-sm-12">
              <div className="cartheader">
                <h1>My Cart</h1>
                <Slider {...settings}>
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className="greybox">
                      <img src={StandardImg} alt="StandardImg" className='b-c-greybox-cart-img' />
                    </div>
                  ))}
                </Slider>
                <div id="carteditbtn">
                  <Link to="/MainUploaddesign">
                    <button>Edit my Design</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="cartbody">
                <div className="cartbodyheader">
                  <h1>Standard Business Cards</h1>
                </div>
                <div className="cartbodycontent">
                  <div className="row">
                    <div className="col-6">
                      <div className="cartbodycontentleft">
                        <h1>Quantity</h1>
                        <select value={quantity} onChange={handleQuantityChange}>
                          {[...Array(100)].map((_, index) => (
                            <option key={index} value={index + 1}>{index + 1}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div id='remove' className="col-6 alignleft">
                      <Link to=""><p>Remove</p></Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h1>Your Preference Selection</h1>
                    </div>
                    <div className="col-6 alignleft">
                      <p>Edit</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <ul>
                        {[...Array(6)].map((_, index) => (
                          <li key={index}>Preference {index + 1}: <span>Option</span></li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="alignleft">
                        {[...Array(6)].map((_, index) => (
                          <li key={index}>+US $29.99</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cartbodyfooter">
                  <div className="row">
                    <div className="col-6">
                        <div className="carttotalcontentleft">
                            <h1>Order Total</h1>
                        </div>
                    </div>
                    <div className="col-6 alignleft">
                      <p>${(quantity * 20).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="carttotal">
                <h1>Order Total</h1>
                <div className="carttotalcontent">
                  <div className="row">
                    <div className="col-6">
                      <h2>Subtotal</h2>
                    </div>
                    <div className="col-6 alignleft">
                      <p>${(quantity * 20).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Shipping</p>
                    </div>
                    <div className="col-6 alignleft">
                      <p>Free</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Taxes</p>
                    </div>
                    <div className="col-6 alignleft">
                      <p>${(quantity * 20 * 0.1).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                        <div className="carttotalcontentbottom">
                            <h1>Order Total</h1>
                        </div>
                    </div>
                    <div className="col-6 alignleft">
                      <p>${(quantity * 20 * 1.1).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                        <div className='payimg'>
                            <img src={DebitImg} alt="DebitImg" />
                        </div>
                    </div>
                  </div>
                </div>
                <div className="carttotalfooter">
                  <Link to="/Account">
                    <button>Sign In & Checkout</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

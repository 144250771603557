import React from 'react';
import { Link } from 'react-router-dom';
import BascCard from '../assets/images/businesscard/basic.png';
import RoundedCard from '../assets/images/businesscard/rounded.png';
import SquareCard from '../assets/images/businesscard/square.png';

const cards = [
  { image: BascCard, title: "Basic Cards", description: "Many desktop publishing packages and web page editors now use", link: "/MainProduct", price: "100 Starts from $17.45" },
  { image: RoundedCard, title: "Rounded Cards", description: "Many desktop publishing packages and web page editors now use", link: "/MainProduct", price: "100 Starts from $17.45" },
  { image: SquareCard, title: "Square Cards", description: "Many desktop publishing packages and web page editors now use", link: "#", price: "100 Starts from $17.45" },
  { image: SquareCard, title: "Square Cards", description: "Many desktop publishing packages and web page editors now use", link: "/MainProduct", price: "100 Starts from $17.45" }
];

const MyOrderTab = () => (
  <div className="Myorder">
    <div className="container">
      <div className="row">
        {cards.map((card, index) => (
          <div className="col-lg-3" key={index}>
            <Link to={card.link}>
              <img src={card.image} alt={card.title} />
              <div className="explorecardtext">
                <h4>{card.title}</h4>
                <p>{card.description}</p>
                <button>
                  <Link to={card.link}>{card.price}</Link>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="row">
        {cards.map((card, index) => (
          <div className="col-lg-3" key={index}>
            <Link to={card.link}>
              <img src={card.image} alt={card.title} />
              <div className="explorecardtext">
                <h4>{card.title}</h4>
                <p>{card.description}</p>
                <button>
                  <Link to={card.link}>{card.price}</Link>
                </button>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default MyOrderTab;

import React, { useEffect, useState } from 'react';
import comlimentBanner from '../assets/images/businesscard/complimentbanner.png'; // Import the image
import cardBanner from '../assets/images/businesscard/cardbanner.png'
import Basic from '../assets/images/businesscard/basic.png'
import RoundedCard from '../assets/images/businesscard/rounded.png'
import SquareCard from '../assets/images/businesscard/square.png'
import Card from '../assets/images/businesscard/card.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { fetchTempletesBySubCategoryId } from '../../services/categoryService';


export const BusinessCards = () => {
  const goBack = () => {
    window.history.go(-1);
  };
  const { pathname } = window.location;
  const id = pathname.split("/").pop(); 
  
  const [subCat, setSubCat] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTempletesBySubCategoryId(id);
        
        setSubCat(response.data);

        console.log('templetes', response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Stop the loader
      }
    };
    fetchData();
  }, [id]);
  
  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Link to="#" onClick={goBack}>
                <i className="fa fa-angle-left" aria-hidden="true"></i> <span>Home / Business Cards</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="businesscard">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
              <div className="bussniessfirst">
                <div className="bunsniessheading">
                  <h2>Business Cards</h2>
                </div>
                <hr />
                <div className="catbox">
                  <h3>Select by Shapes</h3>
                  <ul>
                    <li>
                      <button>
                        <Link to="/category-card">Standard</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="#">Rounded Corner</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="#">Square</Link>
                      </button>
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="catbox">
                  <h3>Select by Cards Textures</h3>
                  <ul>
                    <li>
                      <button>
                        <Link to="#">Matte</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="#">Glossy</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="#">Uncoated</Link>
                      </button>
                    </li>
                    <li>
                      <button style={{ marginTop: '5px' }}>
                        <Link style={{ textDecoration: 'underline' }} to="#">View All Textures</Link>
                      </button>
                    </li>
                  </ul>
                </div>
                <hr />
                <div className="catbox">
                  <h3>Standard Card template</h3>
                  <ul>
                    <li>
                      <button>
                        <Link to="/category-card">Painted Edge</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="/category-card">Bamboo</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="/category-card">Hemp-Blend</Link>
                      </button>
                    </li>
                    <li>
                      <button>
                        <Link to="/category-card">Raised Foil</Link>
                      </button>
                    </li>
                    <li>
                      <button style={{ marginTop: '5px' }}>
                        <Link style={{ textDecoration: 'underline' }} to="#">View All Textures</Link>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12">
              <section className="busnisseccestion" style={{ backgroundImage: `url(${cardBanner})` }}>
                <div className="businessbanner">
                  <div className="row">
                    <div className="col-lg-6">
                      <h1>Business Cards</h1>
                    </div>
                    <div className="col-lg-6">
                      <div id="banner-btn">
                        <button>
                          <Link to="#">Upload Your Design</Link>
                        </button>
                        <button>
                          <Link to="#">Templates</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <div className="row">
                  <div className="card-categor">
                    <h2>Explore Our Business Card Categories</h2>
                  </div>
                </div>
                <div className="businesscard">
                  <div className="row" style={{ gap: "20px 0"}}>
                  {subCat.length > 0 ? (
            subCat.map((subcategory, index) => (
                    <div className="col-md-4 col-10 mx-auto" key={index}>
                       <div className="business-card">
                        <img src={Card} alt="Card" />
                            <div className="card-content">
                                <h3>{subcategory.title}</h3>
                                <p>
                                {subcategory.description}
                                </p>
                                <Link to={`/standard-main/${subcategory.id}`}>
                                    <button>Explore More</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                   ))
                  ) : (
                    <p>No categories available.</p> // Display a message if no data is available
                  )}
                  </div>
                </div>
              </section>

              <section className="explo">
                <div className="row" >
                  <div className="carddesigm-row">
                    <h2>Explore By Cards Design</h2>
                  </div>
                  <div className="explorecard">
                    <div className="row" style={{gap: "20px 0"}}>
                      <div className="col-md-4 col-10 mx-auto">
                        <Link to="/standard-main">
                        <img src={Basic} alt="Basic" />
                          <div className="explorecardtext">
                              <h4>Basic Cards</h4>
                              <p>Many desktop publishing packages and web page editors now use</p>
                              <button>
                                  <Link to="#">100 Starts from $17.45</Link>
                              </button>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-4 col-10 mx-auto">
                        <Link to="/standard-main">
                        <img src={RoundedCard} alt="Basic" />
                          <div className="explorecardtext">
                              <h4>Rounded Cards</h4>
                              <p>Many desktop publishing packages and web page editors now use</p>
                              <button>
                                  <Link to="#">100 Starts from $17.45</Link>
                              </button>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-4 col-10 mx-auto">
                        <Link to="/standard-main">
                        <img src={SquareCard} alt="square" />
                          <div className="explorecardtext">
                              <h4>Square Cards</h4>
                              <p>Many desktop publishing packages and web page editors now use</p>
                              <button>
                                  <Link to="#">100 Starts from $17.45</Link>
                              </button>
                          </div>
                        </Link> 
                      </div>
                    </div>
                    <div className="row seccardrow" style={{ gap: "20px 0"}}>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={Basic} alt="Basic" />
                            <div className="explorecardtext">
                                <h4>Basic Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={RoundedCard} alt="Basic" />
                            <div className="explorecardtext">
                                <h4>Rounded Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={SquareCard} alt="Square" />
                            <div className="explorecardtext">
                                <h4>Square Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                    </div>
                    <div className="row thirdcardrow" style={{ gap: "20px 0"}}>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={Basic} alt="Basic" />
                            <div className="explorecardtext">
                                <h4>Basic Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={RoundedCard} alt="Basic" />
                            <div className="explorecardtext">
                                <h4>Rounded Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={SquareCard} alt="Square" />
                            <div className="explorecardtext">
                                <h4>Square Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link href="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                    </div>
                    <div className="row fourthcardrow" style={{gap: "20px 0"}}>
                        <div className="col-md-4 col-10 mx-auto" >
                          <Link to="/standard-main">
                          <img src={Basic} alt="Basic" />
                            <div className="explorecardtext">
                                <h4>Basic Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4 col-10 mx-auto">
                          <Link to="/standard-main">
                          <img src={RoundedCard} alt="Basic" />
                            <div className="explorecardtext">
                                <h4>Rounded Cards</h4>
                                <p>Many desktop publishing packages and web page editors now use</p>
                                <button>
                                    <Link to="#">100 Starts from $17.45</Link>
                                </button>
                            </div>
                          </Link>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section className="businesscardlastsec" style={{ backgroundImage: `url(${comlimentBanner})` }}>
        <div className="container">
          <div className="row">
            <h2 className='businesscardlastsec-txt-h2'>Design your complimentary logo with ease in just a matter of minutes.</h2>
            <div className="busbtn">
              <button>
                <Link to="#">Get Started</Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
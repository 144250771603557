import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Rounded from '../assets/images/businesscard/b-card-back.jpg';
import basic from '../assets/images/businesscard/b-card-back.jpg'
import Frame from '../assets/images/frame.png';
import cardFront from '../assets/images/businesscard/business-card-back.jpg'

export const BusinessCardDesignReviewMain = () => {
  // Initialize state variables
  const [companyName, setCompanyName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [webOther, setWebOther] = useState('');

  // Handle input change for companyName
  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  // Handle input change for businessType
  const handleBusinessTypeChange = (e) => {
    setBusinessType(e.target.value);
  };

  // Handle input change for webOther
  const handleWebOtherChange = (e) => {
    setWebOther(e.target.value);
  };
  return (
    <section className="designreviewmain">
      <div className="container">
        <div className="row">
          <div className="col-lg-6" id="frontback">
            <div className='bcd-designreviewmain-m-div'>
              <Tabs
                defaultActiveKey="front"  // Ensure "front" matches your Tab eventKey
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="front" title="Front">
                  <img src={cardFront} alt='card front' />
                </Tab>
                <Tab eventKey="back" title="Back">
                  <img src={Rounded} alt="RoundedImg" />
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="designreviewcontent">
              <h1>Design Review</h1>
              <h2>All set? Let’s double check:</h2>
              <ul>
                <li>Text: Is it clear and legible?</li>
                <li>Margin: Is everything within the space?</li>
                <li>Info: Everything accurate? Spelled correctly?</li>
                <li>Images: Is everything clear (no blur)?</li>
              </ul>
              <div className="designcomment designcomment-text-p">
                <p>edit design</p>
              </div>
              <div className="commark">
                <input type="checkbox" id="mark" />
                <label htmlFor="mark">I've reviewed and approved my design.</label>
                <div className="commbtin">
                  <Link to="/business-card-preference"><button>Finish</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessCardDesignReviewMain;

import React from 'react';
import { Link } from 'react-router-dom';
import BascCard from '../assets/images/businesscard/basic.png'
import RoundedCard from '../assets/images/businesscard/rounded.png'
import SquareCard from '../assets/images/businesscard/square.png'

export const RelatedProducts = () => {
  return (
    <section id="related">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1>Explore Related Products</h1>
          </div>
        </div>
        <div className="row" style={{gap: "20px 0"}}>
          <div className="col-lg-3 col-md-4 col-6 mx-auto">
            <Link to="/MainProduct">
            <img src={BascCard} alt="" />
            <div className="explorecardtext">
              <h4>Basic Cards</h4>
              <p>Many desktop publishing packages and web page editors now use</p>
              <button>
                <Link to="/MainProduct">100 Starts from $17.45</Link>
              </button>
            </div>
            </Link>

          </div>

          <div className="col-lg-3 col-md-4 col-6 mx-auto">
          <Link to="/MainProduct">
          <img src={RoundedCard} alt="" />
            <div className="explorecardtext">
              <h4>Rounded Cards</h4>
              <p>Many desktop publishing packages and web page editors now use</p>
              <button>
                <Link to="/MainProduct">100 Starts from $17.45</Link>
              </button>
            </div>
          </Link>
          </div>

          <div className="col-lg-3 col-md-4 col-6 mx-auto">
          <Link to="/MainProduct">
          <img src={SquareCard} alt="" />
            <div className="explorecardtext">
              <h4>Square Cards</h4>
              <p>Many desktop publishing packages and web page editors now use</p>
              <button>
                <Link to="#">100 Starts from $17.45</Link>
              </button>
            </div>
          </Link>
            
          </div>

          <div className="col-lg-3 col-md-4 col-6 mx-auto">
          <Link to="/MainProduct">
          <img src={SquareCard} alt="" />
            <div className="explorecardtext">
              <h4>Square Cards</h4>
              <p>Many desktop publishing packages and web page editors now use</p>
              <button>
                <Link to="/MainProduct">100 Starts from $17.45</Link>
              </button>
            </div>
          </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
import React from 'react'
import { ExlporeHeader } from '../ExploreComponent/ExploreHaeder'
import { ExploreProduct } from '../ExploreComponent/ExploreProductSec'

export const Explore = () => {
  return (
    <>
    <ExlporeHeader/>
    <ExploreProduct/>
    </>
  )
}
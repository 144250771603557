import React from 'react';
import { Link } from 'react-router-dom';
import Google from '../assets/images/google.png';
import Facebook from '../assets/images/facebook.png';

export const Connect = () => {
  return (
    <>
      <section className="acount mb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
              <div className="accconec">
                <div className='account-header'>
                  <h1>sign in with your Printgraphi account.</h1>
                  <div className="accsocialbtn">
                    <button>
                      <Link to="/Account/Signup"><img src={Google} alt="Card" /><span>Continue with Google</span></Link>
                    </button>
                    <button>
                      <Link to="/Account/Signup"><img src={Facebook} alt="Card" /><span>Continue with Facebook</span></Link>
                    </button>
                  </div>
                </div>
                <div className="account-body">
                  <h5 className="acc-title">Or, sign in with email.</h5>
                  <form>
                    <div className="mb-3">
                      <input type="email" className="form-control" placeholder="Email" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                      <input type="password" className="form-control" placeholder="Password" id="exampleInputPassword1" />
                    </div>
                    <div className="mb-3 form-check form-check-acc-sec">
                      <div>
                        <input type="checkbox" className="form-check-input" id="rememberme" />
                        <label className="form-check-label" htmlFor="rememberme">Remember me</label>
                      </div>
                      <div className="forgot-password">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                    </div>
                    <div className='acc-terms'>
                      <p>By signing in, you have read and agree to our <br></br><Link to="/terms-and-conditions">Printgraphi General Terms and Conditions</Link></p>
                    </div>
                    <div className="accbtn">
                      <Link to="/Shipping"><button type="submit" className="singin">Sign in</button></Link>
                      <Link to="/Shipping"><button className="makeacc">Make an Account</button></Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

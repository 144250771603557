import React from 'react';

export const GallerySection = () => {
  // Array to hold the imported images
  const images = [
    { imgSrc1: require('../../../assets/images/gallery/1.jpg') },
    { imgSrc1: require('../../../assets/images/gallery/2.jpg') },
    { imgSrc1: require('../../../assets/images/gallery/3.jpg') },
    { imgSrc1: require('../../../assets/images/gallery/4.jpg') },
    { imgSrc1: require('../../../assets/images/gallery/5.jpg') },
    { imgSrc1: require('../../../assets/images/gallery/6.jpg') }
    // { imgSrc: require('../../../assets/images/gallery/6.jpg'), text: 'Stickers' }
  ];
  // Loop to import images dynamically
  // for (let i = 1; i <= 6; i++) {
  //   try {
  //     const image = require(`../../../assets/images/gallery/${i}.jpg`).default;
  //     images.push(image);
  //   } catch (error) {
  //     console.error(`Error loading image ${i}:`, error);
  //   }
  // }

  return (
    <section id="gallery">
      <div className="container">
        <h1>INSPIRATION GALLERY</h1>
        <div className="container gallery-container">
          <div className="tz-gallery">
            <div className="row">
              {images.map((image, index) => (
                <div key={index} className=" col-md-4 col-6 mx-auto">
                  <img src={image.imgSrc1} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
import React, { useState } from 'react';
import StandardImg from '../assets/images/businesscard/business-card-back.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import DebitImg from '../assets/images/paycard.png';

export default function RecentProjectstab() {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <section className="Ordertotal">
      <h1>Your Recent Projects</h1>
        <div className='card'>
        <div className="container">
        <div className="row">
            <div className="col-lg-3 col-sm-12">
              <div className="cartheader">
                <Slider {...settings}>
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className="greybox">
                      <img src={StandardImg} alt="StandardImg" className='b-c-greybox-cart-img' />
                    </div>
                  ))}
                </Slider>
                <div id="carteditbtn">
                  <Link to="/business-card-detail">
                    <button>Edit my Design</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12">
              <div className="cartbody">
                <div className="cartbodyheader">
                  <h1>Standard Business Cards</h1>
                </div>
                <div className="cartbodycontent">
                  <div className="row">
                    <div className="col-6">
                      <div className="cartbodycontentleft">
                        <h1>Quantity</h1>
                        <select value={quantity} onChange={handleQuantityChange}>
                          {[...Array(100)].map((_, index) => (
                            <option key={index} value={index + 1}>{index + 1}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div id='remove' className="col-6 alignleft">
                      <Link to=""><p>Remove</p></Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h1>Your Preference Selection</h1>
                    </div>
                    <div className="col-6 alignleft">
                      <p>Edit</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <ul>
                        {[...Array(6)].map((_, index) => (
                          <li key={index}>Preference {index + 1}: <span>Option</span></li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="alignleft">
                        {[...Array(6)].map((_, index) => (
                          <li key={index}>+US $29.99</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cartbodyfooter">
                  <div className="row">
                    <div className="col-6">
                        <div className="carttotalcontentleft">
                            <h1>Order Total</h1>
                        </div>
                    </div>
                    <div className="col-6 alignleft">
                      <p>${(quantity * 20).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        </div>
      </section>
  )
}

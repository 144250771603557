import React, { useEffect, useState } from 'react';
import { usePhotoEditor } from 'react-photo-editor';
import Delete from '../assets/images/delete.png';
import Rotate from '../assets/images/rotate.png';
import Fit from '../assets/images/fit.png';
import Full from '../assets/images/full.png';
import { Link, useNavigate } from 'react-router-dom';
// For typescript example see: https://github.com/musama619/react-photo-editor/blob/main/src/examples/DesignEditArea.tsx
import Eye from '../assets/images/eye.png';
import Saturate from '../assets/images/Saturate.svg';
import Grayscale from '../assets/images/Grayscale.svg';
import { MdBrightness6 } from 'react-icons/md';
import { IoIosContrast } from 'react-icons/io';
import { TbZoomReplace } from 'react-icons/tb';
import { LuFlipHorizontal2, LuFlipVertical2 } from 'react-icons/lu';
import { Button, Modal } from 'react-bootstrap';
import { addToCart, fetchAttributesByType, fetchCartByIdApi, fetchProductById, fetchProductSizeType } from '../../services/productService';
import { BASE_URL } from "../../services/Constant";

export const DesignEditArea = () => {
  const [frontFile, setFrontFile] = useState();
  const [backFile, setBackFile] = useState();
  const [isEditingBack, setIsEditingBack] = useState(false);
  const setFileData = (e, isBack) => {
    if (e?.target?.files && e.target.files.length > 0) {
      if (isBack) {
        setBackFile(e.target.files[0]);
      } else {
        setFrontFile(e.target.files[0]);
      }
    }
  };
  const toggleEditing = () => setIsEditingBack((prev) => !prev);
  const editingFile = isEditingBack ? backFile : frontFile;

  const {
    canvasRef,
    imageSrc,
    brightness,
    setBrightness,
    contrast,
    setContrast,
    saturate,
    setSaturate,
    grayscale,
    setGrayscale,
    rotate,
    setRotate,
    flipHorizontal,
    setFlipHorizontal,
    flipVertical,
    setFlipVertical,
    zoom,
    setZoom,
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handleWheel,
    downloadImage,
    resetFilters,
  } = usePhotoEditor({ file: editingFile });

  
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedProductType, setSelectedProductType] = useState(null);
  
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [modalShowPreview, setModalShowPreview] = useState(false);
  const handleClosePreview = () => setModalShowPreview(false);
  const handleShowPreview = () => setModalShowPreview(true);

  const [succModalShow, setSuccModalShow] = useState(false);
  const handleCloseSucc = () => setSuccModalShow(false);
  const handleShowSucc = () => setSuccModalShow(true);
  const [saveFront, setSaveFront] = useState(false);
  const [saveBack, setSaveBack] = useState(false);

  const { pathname } = window.location;
  const id = pathname.split("/").pop();
  const [product, setProduct] = useState({});
  const [productType, setProductType] = useState({});
  const [productAttr, setProductAttr] = useState([]);
  const [attributeData, setAttributeData] = useState([]);
  const [attributeHeight, setAttributeHeight] = useState("0px");
const [attributeWidth, setAttributeWidth] = useState("0px");
  const [productTypeHori, setProductTypeHori] = useState([]);
  const [productTypeVerti, setProductTypeVerti] = useState([]);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [editedFrontImage, setEditedFrontImage] = useState(null);
  const [editedBackImage, setEditedBackImage] = useState(null);
  const saveEditedImage = () => {
    const editedImage = canvasRef.current.toDataURL(); // or your method to get edited image URL
    if (isEditingBack) {
      setEditedBackImage(editedImage);
      setSaveBack(true);
    } else {
      setEditedFrontImage(editedImage);
      setSaveFront(true);
    }
  };
    
  
  useEffect(() => {
    const fetchProductAndAttributes = async () => {
      try {
        const productData = await fetchCartByIdApi(id);
        setProduct(productData.data);
        console.log('productData', product);
        // Ensure units (px) are added
        setAttributeHeight(`${productData.data[0].attributeSize[0]?.height || 0}px`);
        setAttributeWidth(`${productData.data[0].attributeSize[0]?.width || 0}px`);
        console.log('height', attributeHeight, attributeWidth);
        
        setProductType(productData.data.type);
  
        const attributes = productData.data[0].attribute;
        setProductAttr(attributes);
        console.log('productAttr', productAttr);
        console.log('attributes', attributes);
        const allFetchedAttributes = [];
        await Promise.all(
          attributes.map(async (attr) => {
            try {
              const attributeData = await fetchAttributesByType(attr.type);
              allFetchedAttributes.push(...attributeData.data);
            } catch (err) {
              console.error("Error fetching attribute data:", err);
            }
          })
        );
  
        setAttributeData(allFetchedAttributes);
  
        // Set default selected attributes to the first attribute of each category
        const defaultSelected = {};
        attributes.forEach((attr) => {
          const firstAttribute = allFetchedAttributes.find(
            (attribute) => attribute.type === attr.type
          );
          if (firstAttribute) {
            defaultSelected[attr.type] = firstAttribute.id;
          }
        });
        setSelectedAttributes(defaultSelected); // Set default selected attributes
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    const fetchProductType = async () => {
      try {
        const productData = await fetchProductSizeType(product.type);
        setProductTypeHori(Array.isArray(productData.data.horizontal) ? productData.data.horizontal : [productData.data.horizontal]);
        setProductTypeVerti(Array.isArray(productData.data.vertical) ? productData.data.vertical : [productData.data.vertical]);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductType();
    fetchProductAndAttributes();
  }, [id]);

 
  const handleProductTypeSelect = (type, productId) => {
    setSelectedProductType({ type, productId });
  };

  const userToken = localStorage.getItem('userToken');
console.log(userToken)
const navigate = useNavigate();
 
const handleAddToCart = async () => {
  const userToken = localStorage.getItem("userToken");

  if (!userToken) {
    navigate("/login");
    return;
  }

  // Extract the first two attribute IDs
  const firstTwoAttributeIds = productAttr
    .slice(0, 2) // Get the first two attributes
    .map((attr) => attr.id); // Extract their IDs

  // Combine the first two IDs with the selected product ID
  const allAttributeIds = [
    ...firstTwoAttributeIds, // Spread the first two IDs
    selectedProductType?.productId, // Add the selected product ID
  ];

  const formData = new FormData();
  // formData.append("front_image", frontFile || null);
  // formData.append("quantity", '1');
  // formData.append("back_image", backFile || null);
  formData.append("attribute_id", JSON.stringify(allAttributeIds)); // Add combined IDs

  try {
    const response = await fetch(`${BASE_URL}/api/add_to_cart_update/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: formData,
    });
    const result = await response.json();
    console.log("Add to cart response:", result);
    // Uncomment these lines if you want to handle success messages or reload
    // if (result.status === "success") {
    //   window.reload();
    // }
    // handleShowSucc(result.status === "success" ? "Added to Cart Successfully" : "Failed to add to cart");
  } catch (error) {
    console.error("Error adding to cart:", error);
    // Uncomment this line if you want to show error messages
    // handleShowSucc("Error adding to cart: " + error.message);
  }
};

const handlePreview = () => {
  if (!editedFrontImage && !editedBackImage) {
    alert("Please edit and save at least one image before previewing.");
    return;
  }
  handleShowPreview(); // Show the modal
};


  return (
    <>
      <section className="design-edit-area">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="photo-editor">      
              <div className="controls">
                <div>
                  <label>
                  <MdBrightness6/>
                    Brightness</label>
                  <input
                    type="range"
                    min="0"
                    max="200"
                    value={brightness}
                    onChange={(e) => setBrightness(Number(e.target.value))}
                  />
                </div>

                <div>
                  <label><IoIosContrast />
                  Contrast</label>
                  <input
                    type="range"
                    min="0"
                    max="200"
                    value={contrast}
                    onChange={(e) => setContrast(Number(e.target.value))}
                  />
                </div>

                <div>
                  <label><img width={16} src={Saturate} alt="" />Saturate</label>
                  <input
                    type="range"
                    min="0"
                    max="200"
                    value={saturate}
                    onChange={(e) => setSaturate(Number(e.target.value))}
                  />
                </div>

                <div>
                  <label><img width={16} src={Grayscale} alt="" /> Grayscale</label>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={grayscale}
                    onChange={(e) => setGrayscale(Number(e.target.value))}
                  />
                </div>

                <div>
                  <label><img width={18} src={Rotate} alt="" /> Rotate</label>
                  <input
                    type="range"
                    min="0"
                    max="360"
                    value={rotate}
                    onChange={(e) => setRotate(Number(e.target.value))}
                  />
                </div>

                <div>
                  <label><TbZoomReplace />
                  Zoom</label>
                  <input
                    type="range"
                    min="0.1"
                    max="3"
                    step="0.1"
                    value={zoom}
                    onChange={(e) => setZoom(Number(e.target.value))}
                  />
                </div>

                <div>
                  <label onChange={(e) => setFlipHorizontal(e.target.checked)}>
                  <LuFlipHorizontal2 />
                    <input
                      type="checkbox"
                      checked={flipHorizontal}
                      style={{display:'none'}}
                    />
                    Flip Horizontal
                  </label>
                </div>

                <div>
                  <label onChange={(e) => setFlipVertical(e.target.checked)}>
                  <LuFlipVertical2 />

                    <input
                      type="checkbox"
                      checked={flipVertical}
                      style={{display:'none'}}
                    />
                    Flip Vertical
                  </label>
                </div>

                <div className="buttons">
                  <button onClick={resetFilters}>Reset</button>
                  <button onClick={downloadImage}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="uploading-sec mt-5">
        <div className="container">
          <div className="row" style={{ gap: "20px 0" }}>
            <div className="col-lg-4">
              <div className="uploading-area">
                <h1>Upload Your Design</h1>
                <div className='uploading-area-sub-header'>
                  <div>
                    <h5>Standard Business Cards</h5>
                    <small>100 from $17.99</small>
                  </div>
                  <button className='change-size-btn'  onClick={handleShow}>Change size</button>
                </div>

                {/* Front/Back Image Upload */}
              {/* Front/Back Image Upload */}
              <div className='row'>
                  <div className='col-lg-12 col-6'>
                  {isEditingBack ? (
                   <div className="upload-area">
                   <small>Back</small>
                   <div className="upload-button">
                     <label htmlFor='readUrlFront' className="btn">
                       Upload Back
                     </label>
                    
                      <input
                      name='readUrlFront'
                      id='readUrlFront'
type="file"
onChange={(e) => setFileData(e, true)}
style={{ display: 'none' }}
/>


                   </div>
                 </div>
                  ) : (
                    <div className="upload-area">
                    <small>Front</small>
                    <div className="upload-button">
                      <label htmlFor='readUrlFront' className="btn">
                        Upload front
                      </label>
                     
                       <input
                       name='readUrlFront'
                       id='readUrlFront'
 type="file"
 onChange={(e) => setFileData(e, false)}
 style={{ display: 'none' }}
/>


                    </div>
                  </div>
                    
                  )
                  }
                  </div>
                </div>


                {/* Preview Button */}
                <div className="row mt-4" style={{ gap: "10px 0" }}>
                  <div className='col-lg-12 col-6'>
                    <div className="prebtn flexiblity">
         {saveBack == false ? (
          null
         ) : (
<button className="prebtn-prv-btn" onClick={handlePreview}>
  <img src={Eye} alt="" /> Preview
</button>
          )}

                      
                     

                    </div>
                  </div>
                  <div className='col-lg-12 col-6'>
                    <div className="finishbtn">
                      <Link to="/DesignReview">
                        <button>Finish</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8" style={{display:'contents'}}>
              <div className="rendering">
                <div className='rendering-card-main-div-sec' style={{ position: 'relative', height: attributeHeight, width: attributeWidth}}
                    >
                  <div className='rendering-card-bdr-div-sec'>
                  {imageSrc && (
        <div className="canvas-container">
          <canvas
            ref={canvasRef}
            onMouseDown={handlePointerDown}
            onMouseMove={handlePointerMove}
            onMouseUp={handlePointerUp}
            onWheel={handleWheel}
          />
        </div>
      )}
                  </div>

                  
                </div>
                <div className="editbtn">
                  {saveFront == false ? (
                    <button className="bg-success text-white" onClick={saveEditedImage}>
                      Save
                      </button>
                      ) : (
                        <>
                        {saveFront == true ? (
                        <button className="bg-success text-white" onClick={saveEditedImage}>
                      Save
                      </button>
                      ) : (
                        null
                      )}
                      {isEditingBack ? (
 <button onClick={toggleEditing}>
  Edit Front Image
</button>
                      ): (
                        <button onClick={toggleEditing}>
  Edit Back Image
</button>
                      )}
                      
                     </>
                        )}

                 
                  </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-12">
                  
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Modal show={modalShowPreview} onHide={handleClosePreview} size="lg" centered>
  <Modal.Header closeButton>
    <Modal.Title>Preview Your Edited Images</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
      {editedFrontImage ? (
        <div>
          <h5>Front</h5>
          <img src={editedFrontImage} alt="Edited Front" style={{ width: "300px", height: "auto" }} />
        </div>
      ) : (
        <p>No Front Image Edited</p>
      )}
      {editedBackImage ? (
        <div>
          <h5>Back</h5>
          <img src={editedBackImage} alt="Edited Back" style={{ width: "300px", height: "auto" }} />
        </div>
      ) : (
        <p>No Back Image Edited</p>
      )}
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClosePreview}>
      Close
    </Button>
  </Modal.Footer>
</Modal>


      <Modal
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="custom-model-wrap">
          <div className="pop-up-content-wrap">
            <div className="model-header">
              <h1>Standard Business Cards</h1>
              <h2>100 from $17.99</h2>
              <h2>Horizontal</h2>
            </div>
          </div>
          <div className="horizontalcard">
            <div className="row">
            {productTypeHori.map((productHori, index) => (
                  <div className="col-lg-6 my-4" key={index}>
                    <div className={`model-card ${selectedProductType?.productId === productHori.id && selectedProductType?.type === 'horizontal' ? 'selected' : ''}`}
                      onClick={() => handleProductTypeSelect('horizontal', productHori.id)}>
                      <div className="grey-card">
                        <div className="innerwhite p-2"><img src={`${BASE_URL}/${productHori.image}`} style={{ objectFit: 'contain', width: '100%', height: '100%' }} /></div>
                      </div>
                      <div className="model-card-heading">
                        <p>{productHori.name}</p>
                      </div>
                      <div className="model-cardprice">
                        <h3>${productHori.price}</h3>
                      </div>
                    </div>
                  </div>
                ))}

            </div>
          </div>
          <div className="vertical">
            <div className="model-header">
              <h2>Vertical</h2>
            </div>
            <div className="row">
            {productTypeVerti.map((productVerti, index) => (
                  <div className="col-lg-6" key={index}>
                    <div className={`model-card ${selectedProductType?.productId === productVerti.id && selectedProductType?.type === 'vertical' ? 'selected' : ''}`}
                      onClick={() => handleProductTypeSelect('vertical', productVerti.id)}>
                      <div className="grey-card">
                        <div className="portraitinnerwhite p-2"><img src={`${BASE_URL}/${productVerti.image}`} style={{ objectFit: 'contain', width: '100%', height: '100%' }} /></div>
                      </div>
                      <div className="model-card-heading">
                        <p>{productVerti.name}</p>
                      </div>
                      <div className="model-cardprice">
                        <h3>${productVerti.price}</h3>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="potbtn">
                  {/* <Link to="/MainUploaddesign"> */}
                  {selectedProductType?.productId ? (
                    <button className="text-white" onClick={handleAddToCart}>Potrait</button>
                  ) : (
                    <button className="bg-danger text-white">Select a size</button>
                    )}
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

    </Modal>
      <Modal
      show={succModalShow}
      onHide={handleCloseSucc}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="custom-model-wrap">
          <div className="pop-up-content-wrap">
            <div className="model-header">
              <h1>Success</h1>
              <h2>Message</h2>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
   
  );
};

export default DesignEditArea;

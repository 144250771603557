import React from 'react'
import { BusinessCardMyCartBack } from '../BusinessCardCartComponent/BusinessCardMyCartBack'
import { BusinessCardCartRelatedProduct } from '../BusinessCardCartComponent/BusinessCardCartRelatedProduct'
import { BusinessCardOrderTotal } from '../BusinessCardCartComponent/BusinessCardOrderTotal'


export const BusinessCardMyCart = () => {
  return (
    <>
    <BusinessCardMyCartBack/>
    <BusinessCardOrderTotal/>
    <BusinessCardCartRelatedProduct/>
    </>
  )
}
import { Link } from "react-router-dom";

export const ProductFisrsec = () => {
  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <section id='slidebread' className="breadcrumbs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link to="#" onClick={goBack}><i className="fa fa-angle-left" aria-hidden="true"></i><span>Home / Business Cards</span></Link>
          </div>
        </div>
      </div>
    </section>
  );
};   
import React from 'react'
import { BusinessCardPreferencesBack } from '../BusinessCardPreferencesComponent/BusinessCardPreferenceBack'
import { BusinessCardPreferencesMainsec } from '../BusinessCardPreferencesComponent/BusinessCardPreferenceMainSec'
import { BusinessCarsNeedHelp } from '../BusinessCardPreferencesComponent/BusinessCardNeedHelp'

export const BusinessCardPreference = () => {
  return (
    <>
        <BusinessCardPreferencesBack />
        <BusinessCardPreferencesMainsec />
        {/* <BusinessCarsNeedHelp /> */}
    </>
  )
}

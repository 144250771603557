import React, { useState } from 'react';
import DiscriptionImage from '../assets/images/discrip.png'

export const Description = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section className="description">
      <div className="container">
        <div className="row">
          <ul className="tabs">
            <li className={`tab-link ${activeTab === 'tab-1' ? 'current' : ''}`} data-tab="tab-1" onClick={() => handleTabClick('tab-1')}>Description</li>
            <li className={`tab-link ${activeTab === 'tab-2' ? 'current' : ''}`} data-tab="tab-2" onClick={() => handleTabClick('tab-2')}>FAQs</li>
            <li className={`tab-link ${activeTab === 'tab-3' ? 'current' : ''}`} data-tab="tab-3" onClick={() => handleTabClick('tab-3')}>Reviews</li>
          </ul>

          <div id="tab-1" className={`tab-content ${activeTab === 'tab-1' ? 'current' : ''}`}>
            <div className="row">
              <div className="col-lg-8">
                <h1>Create a one-of-a-kind look for your card – quickly and easily.</h1>
                <ul>
                  <li>Standard shape and corners included </li>
                  <li>Premium paper upgrades </li>
                  <li>Square shape upgrade available</li>
                  <li>Rounded corner upgrade available</li>
                  <li>Embossed gloss, raised foil & foil accent upgrades</li>
                  <li>Quantities from 50 to 10,000+ </li>
                </ul>
                <p>
                    One-of-a-kind cards<br />
                    Your business is unique, and your business cards should be, too. Whether you’re making first impressions, rewarding regulars with a loyalty card or giving satisfied clients your contact info for next time, we’re here to help you look
                    and feel ready to impress. Create a card that fits your business needs, personality, and style.
                </p>
                <p>
                    One-of-a-kind cards<br />
                    Your business is unique, and your business cards should be, too. Whether you’re making first impressions, rewarding regulars with a loyalty card, or giving satisfied clients your contact info for next time, we’re here to help you look
                    and feel ready to impress. Create a card that fits your business needs, personality, and style.
                </p>
                <p>
                    Lots of options<br />
                    From traditional papers to thicker, heavier stocks and attention-grabbing special finishes, you’ve got a lot of great choices for your cards. Our standard paper options include glossy, matte, or uncoated, and we also offer premium
                    upgrades like linen, recycled matte, kraft, and even hemp-blend or bamboo. And not only that, you can go with a square shape or rounded corners, too. For more info, go to the “Options” section. And for ideas and inspiration, check out
                    some.
                </p>
                <p>
                    Step-by-step design process<br />
                    Our user-friendly design studio makes business card printing easier than ever. You can upload your own complete design, or you can pick from a wide range of templates, all tailored to specific industries, themes, and uses. From there,
                    it’s quick and easy to add your own text, logo, images, and more. To give you a better idea of what you’re working with before you start designing, check out our guide to standard business card dimensions. Need more than 10,000? Send us
                    a request.
                </p>

              </div>
              <div className="col-lg-4">
                <img src={DiscriptionImage} alt="" />
              </div>
            </div>
          </div>

          <div id="tab-2" className={`tab-content ${activeTab === 'tab-2' ? 'current' : ''}`}>
            <div className="row">
              <div className="col-lg-8">
                <h1>Create a one-of-a-kind look for your card – quickly and easily.</h1>
                <ul>
                  <li>Standard shape and corners included </li>
                  <li>Premium paper upgrades </li>
                  <li>Square shape upgrade available</li>
                  <li>Rounded corner upgrade available</li>
                  <li>Embossed gloss, raised foil & foil accent upgrades</li>
                  <li>Quantities from 50 to 10,000+ </li>
                </ul>
                <p>
                    One-of-a-kind cards<br />
                    Your business is unique, and your business cards should be, too. Whether you’re making first impressions, rewarding regulars with a loyalty card or giving satisfied clients your contact info for next time, we’re here to help you look
                    and feel ready to impress. Create a card that fits your business needs, personality, and style.
                </p>
                <p>
                    One-of-a-kind cards<br />
                    Your business is unique, and your business cards should be, too. Whether you’re making first impressions, rewarding regulars with a loyalty card, or giving satisfied clients your contact info for next time, we’re here to help you look
                    and feel ready to impress. Create a card that fits your business needs, personality, and style.
                </p>

              </div>
              <div className="col-lg-4">
                <img src={DiscriptionImage} alt="" />
              </div>
            </div>
          </div>

          <div id="tab-3" className={`tab-content ${activeTab === 'tab-3' ? 'current' : ''}`}>
            <div className="row">
              <div className="col-lg-8">
                <h1>Create a one-of-a-kind look for your card – quickly and easily.</h1>
                <ul>
                  <li>Standard shape and corners included </li>
                  <li>Premium paper upgrades </li>
                  <li>Square shape upgrade available</li>
                  <li>Rounded corner upgrade available</li>
                  <li>Embossed gloss, raised foil & foil accent upgrades</li>
                  <li>Quantities from 50 to 10,000+ </li>
                </ul>
                <p>
                    One-of-a-kind cards<br />
                    Your business is unique, and your business cards should be, too. Whether you’re making first impressions, rewarding regulars with a loyalty card or giving satisfied clients your contact info for next time, we’re here to help you look
                    and feel ready to impress. Create a card that fits your business needs, personality, and style.
                </p>

              </div>
              <div className="col-lg-4">
                <img src={DiscriptionImage} alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};
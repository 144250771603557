import React from "react";
import { Link } from "react-router-dom";

const PaymentForm = () => {
    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Add logic to handle form submission (e.g., send payment data to server)
    };

    return (
        <div className="payform">
            <h2>Payment Method</h2>
            <h6>Credit or Debit card</h6>
            <p>Your payements are secured, Your Details are confedentials</p>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-12">
                        <input type="text" id="cardNumber" name="cardNumber" placeholder="Card Number" required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <input type="text" id="expiryDate" name="expiryDate" placeholder="Expiration Date" required />
                    </div>
                    <div className="col-lg-6">
                        <input type="text" id="cvv" name="cvv" placeholder="CVV" required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <input type="text" id="name" name="name" placeholder="Name on Card" required />
                    </div>
                    <div className="col-lg-6">
                        <input type="text" id="securityCode" name="securityCode" placeholder="Security Code" required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rem">
                            <input type="checkbox" id="rememberCard" name="rememberCard" className="form-check-input" />
                            <label htmlFor="rememberCard" className="form-check-label">
                                Remember this card for the future
                            </label>
                        </div>
                    </div>
                </div>
                <div className="paybtn">
                    <Link to="/ThankYou"><button type="submit">Pay Now</button></Link>
                </div>
            </form>
        </div>
    );
};

export default PaymentForm;

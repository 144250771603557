import React from "react";
import Card from "../assets/images/businesscard/card.png";
import { Link } from "react-router-dom";
import RoundedCard from "../assets/images/businesscard/rounded.png";
import SquareCard from "../assets/images/businesscard/square.png";
import Basic from "../assets/images/businesscard/basic.png";
import { IoIosArrowBack } from "react-icons/io";
import { Dropdown } from "react-bootstrap";

const Main = () => {
  return (
    <>
      <div className="container">
        <section className="explo">
          <div className="row ">
            <div className="carddesigm-row d-flex justify-content-between ">
              <Link to="/business-card">
                <div className="standard-card-temp-sort-text-bk">
                  <IoIosArrowBack className="standard-card-temp-sort-svg-bk" />
                  <h2>Standard Business Card templates</h2>
                </div>
              </Link>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="standard-card-temp-sort-btn"
                  >
                    Sort
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="explorecard">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={Basic} alt="Basic" />
                    <div className="explorecardtext">
                      c z<p>Standard Business Card templates</p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={SquareCard} alt="square" />
                    <div className="explorecardtext">
                      <h4>Square Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row seccardrow">
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={Basic} alt="Basic" />
                    <div className="explorecardtext">
                      c z<p>Standard Business Card templates</p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={SquareCard} alt="square" />
                    <div className="explorecardtext">
                      <h4>Square Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row thirdcardrow">
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={Basic} alt="Basic" />
                    <div className="explorecardtext">
                      c z<p>Standard Business Card templates</p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={SquareCard} alt="square" />
                    <div className="explorecardtext">
                      <h4>Square Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row fourthcardrow">
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={Basic} alt="Basic" />
                    <div className="explorecardtext">
                      c z<p>Standard Business Card templates</p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={RoundedCard} alt="Basic" />
                    <div className="explorecardtext">
                      <h4>Rounded Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3">
                  <Link to="/business-card-product">
                    <img src={SquareCard} alt="square" />
                    <div className="explorecardtext">
                      <h4>Square Cards</h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use
                      </p>
                      <button>
                        <Link to="#">100 Starts from $17.45</Link>
                      </button>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Main;

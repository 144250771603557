import React from 'react'
import { ProductFisrsec } from '../BusinessCardMainProductComponent/ProductFisrsec'
import { ProductInfo } from '../BusinessCardMainProductComponent/ProductInfo'
import { Description } from '../BusinessCardMainProductComponent/Description'
import { RelatedProducts } from '../BusinessCardMainProductComponent/RelatedProducts'
import { ProductReview } from '../BusinessCardMainProductComponent/ProductReview'

const BusinessCardMainProduct = () => {
  return (
    <>
      <ProductFisrsec />
      <ProductInfo />
      <Description />
      <RelatedProducts />
      <ProductReview />
    </>
  )
}

export default BusinessCardMainProduct

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cardFront from '../assets/images/businesscard/business-card-back.jpg';
import cardBack from '../assets/images/businesscard/b-card-back.jpg';
import ThicknessImg from '../assets/images/product/proimg.png'
import Textures01 from '../assets/images/product/textures01.png'
import Textures02 from '../assets/images/product/textures02.png'
import Textures03 from '../assets/images/product/textures03.png'
import StandardImg from '../assets/images/product/cornersimg.png'
import RoundedImg from '../assets/images/product/Roundedborders.png'
import { Button } from 'react-bootstrap';
import HeadPhones  from '../assets/images/Headphone.png'

export const PreferencesMainsec = () => {
  const [totalAmount, setTotalAmount] = useState(33.89); // Initial total amount
  const [selectedThickness, setSelectedThickness] = useState(null); // State for selected thickness option
  const [selectedTexture, setSelectedTexture] = useState(null); // State for selected texture option
  const [selectedCorners, setSelectedCorners] = useState(null); // State for selected corners option

  const [showFront, setShowFront] = useState(true);

  const toggleImage = () => {
    setShowFront(!showFront);
  };

  // Event handler for updating total amount based on thickness selection
  const handleButtonClick = (amount) => {
    setTotalAmount(totalAmount + amount);
  };

  // Event handler for updating total amount based on texture selection
  const handleButtonClick2 = (amount) => {
    setTotalAmount(totalAmount + amount);
  };

  // Event handler for updating total amount based on corners selection
  const handleButtonClick3 = (amount) => {
    setTotalAmount(totalAmount + amount);
  };
  return (
    <>
      <section className="designreviewmain">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" >
            <div id="frontback" className="designreviewmain-img-btn-sec-m-div">
              <div className='designreviewmain-img-prv-sec'>
                <img src={showFront ? cardFront : cardBack} alt='img' />
              </div>
              <div className='designreviewmain-btn-chng-sec'>
                <button className={showFront ? 'active' : ''} onClick={() => setShowFront(true)}>Front</button>
                <button className={!showFront ? 'active' : ''} onClick={() => setShowFront(false)}>Back</button>
              </div>
            </div>
            </div>
            <div className="col-lg-6">
              <div className="Preferencearea">
                <div className="designreviewcontent">
                  <h1>Your Preferences</h1>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                </div>
                <div className="ticknessarea">
                  <h3>Thickness</h3>
                  {[1, 2, 3].map(index => (
                    <div className="ticknessproductarea" key={index}>
                      <button className={`business-card-ticknessproductarea-btn ${selectedThickness === index ? 'active' : ''}`} onClick={() => handleButtonClick(index === 1 ? 0.00 : index === 2 ? 1.00 : 2.50)}>
                        <div className="row">
                          <div className="col-2">
                            <img src={ThicknessImg} alt="Product" />
                          </div>
                          <div className="col-10">
                            <div className="proprce">
                              <p>+ $ {index === 1 ? '0.00' : index === 2 ? '1.00' : '2.50'}</p>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="texturesarea">
                  <h3>Textures</h3>
                  <div className="texturesproarea">
                    <button className={`business-card-ticknessproductarea-btn ${selectedTexture === 1 ? 'active' : ''}`} onClick={() => handleButtonClick2(0.00)}>
                      <div className="row">
                        <div className="col-2">
                          <img src={Textures01} alt="Product" />
                        </div>
                        <div className="col-10">
                          <div className="proprce">
                            <p>+ $ 0.00</p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="texturesproarea">
                    <button className={`business-card-ticknessproductarea-btn ${selectedTexture === 2 ? 'active' : ''}`} onClick={() => handleButtonClick2(1.00)}>
                      <div className="row">
                        <div className="col-2">
                          <img src={Textures02} alt="Product" />
                        </div>
                        <div className="col-10">
                          <div className="proprce">
                            <p>+ $ 1.00</p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="texturesproarea">
                    <button className={`business-card-ticknessproductarea-btn ${selectedTexture === 3 ? 'active' : ''}`} onClick={() => handleButtonClick2(2.50)}>
                      <div className="row">
                        <div className="col-2">
                          <img src={Textures03} alt="Product" />
                        </div>
                        <div className="col-10">
                          <div className="proprce">
                            <p>+ $ 2.50</p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="cornersarea">
                  <h3>Corners</h3>
                  <div className="cornersprodarea">
                    <button className={`business-card-ticknessproductarea-btn ${selectedCorners === 1 ? 'active' : ''}`} onClick={() => handleButtonClick3(0.00)}>
                      <div className="row">
                        <div className="col-md-2 col-4">
                          <img src={StandardImg} alt="Product" />
                        </div>
                        <div className="col-7">
                          <div className="contentarea">
                            <h4>Standard borders</h4>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div className="proprce">
                            <p>+ $ 0.00</p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="cornersprodarea">
                    <button className={`business-card-ticknessproductarea-btn ${selectedCorners === 2 ? 'active' : ''}`} onClick={() => handleButtonClick3(2.50)}>
                      <div className="row">
                        <div className="col-md-2 col-4">
                          <img src={RoundedImg} alt="Product" />
                        </div>
                        <div className="col-7">
                          <div className="contentarea">
                            <h4>Rounded borders</h4>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                          </div>
                        </div>
                        <div className="col-md-3 col-12">
                          <div className="proprce">
                            <p className=''>+ $ 2.50</p>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="needhelp">
        <div className="container">
          <div className="row justify-content-between" >
            <div className="col-md-3">
              <Link to="#">
                <div className="contactarea">
                  <img src={HeadPhones} alt="call" />
                  <div className="contactinfo">
                    <h3>Need Help?</h3>
                    <p>Call us at : +1 808 695 8520</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-8">
              <div className='barpricingarea-p-main-div'>
              <div className="barpricingarea">
                <div className='cartprice'>
                  <small>$ 48.55</small>
                  <h3 id='totalAmount'>$ {totalAmount.toFixed(2)}</h3>
                </div>
                <p>Get it as soon as Tuesday, Feb 27th to 02451</p>
              </div>
              <div className="qntycartbtn">
                <div className="qntyarea"><Link to="#"> <Button>Quantity <span>(100)</span></Button></Link></div>
                <div className="cartbtn"><Link to="/business-card-explore"><Button>Add to cart</Button></Link></div>
              </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default PreferencesMainsec;

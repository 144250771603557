import React, { useState } from 'react';
import VisaCard from '../assets/images/Dashoard/visa.png';
import MasterCard from '../assets/images/Dashoard/masteracard.png';
import Payoneer from '../assets/images/Dashoard/payoneer.png';
import Paypal from '../assets/images/Dashoard/paypal.png';
import AmericanExpress from '../assets/images/Dashoard/americanexpress.png';

const PaymentsMethodtab = () => {
  const [formData, setFormData] = useState({
    selectedCard: '',
    cardNumber: '',
    expiryDate: '',
    securityCode: '',
    setDefault: false
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = () => {
    setFormData({ ...formData, setDefault: !formData.setDefault });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form data:', formData);
    // Add your form submission logic here
  };

  const cardImages = [VisaCard, MasterCard, Payoneer, Paypal, AmericanExpress];

  return (
    <div className="paymentmethodtab">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Payment Method</h2>
            <form onSubmit={handleSubmit}>
              <div className="paymentcardtab">
                {cardImages.map((card, index) => (
                  <div id="cardpay" key={index}>
                    <label>
                      <input
                        type="radio"
                        name="selectedCard"
                        value={card}
                        checked={formData.selectedCard === card}
                        onChange={handleInputChange}
                      />
                      <img src={card} alt={card} />
                    </label>
                  </div>
                ))}
              </div>
              <div className="paymentcardformation">
                <input
                  type="text"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleInputChange}
                  placeholder="Card Number"
                />
                <input
                  type="text"
                  name="expiryDate"
                  value={formData.expiryDate}
                  onChange={handleInputChange}
                  placeholder="Expiry Date"
                />
                <input
                  type="text"
                  name="securityCode"
                  value={formData.securityCode}
                  onChange={handleInputChange}
                  placeholder="Security Code"
                />
                <div className="default">
                  <label>
                    <input
                      type="checkbox"
                      checked={formData.setDefault}
                      onChange={handleCheckboxChange}
                    />
                    Set as default
                  </label>
                  <div className="savebtn">
                    <button type="submit">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsMethodtab;

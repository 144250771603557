import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import Google from '../assets/images/google.png';
import Facebook from '../assets/images/facebook.png';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';

const libraries = ['places'];

const RegisterComponent = () => {
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone_no: "",
        country: "",
        city: "",
        business_address: "",
        role: "user",
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const navigate = useNavigate();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDg6Ci3L6yS5YvtKAkWQjnodGUtlNYHw9Y",
        libraries, // Include the 'places' library
    });

    const onLoadAutocomplete = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            setFormData({
                ...formData,
                business_address: place.formatted_address,
                city: place.address_components.find(component => component.types.includes("locality"))?.long_name || '',
                country: place.address_components.find(component => component.types.includes("country"))?.long_name || '',
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRegistration = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://printgraphibackend.testingwebsitelink.com/api/registerd', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log("Registration response:", response.data);
            navigate(`/otp-screen/${formData.email}`);
        } catch (error) {
            console.error("Error registering user:", error);
            setErrorMessage(error.response.data.message); 
            setShowModal(true); 
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className='register-bg-img'>
                <div className="container">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="accconec login-bg-color">
                                <div className='account-header'>
                                    <h1>Sign Up With Your Printgraphi Account.</h1>
                                    <div className="accsocialbtn">
                                        <button>
                                            <Link to="/Account/Signup">
                                                <img src={Google} alt="Google" /><span>Continue with Google</span>
                                            </Link>
                                        </button>
                                        <button>
                                            <Link to="/Account/Signup">
                                                <img src={Facebook} alt="Facebook" /><span>Continue with Facebook</span>
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                                <div className="account-body">
                                    <h5 className="acc-title">Or, sign up with email.</h5>
                                    <form onSubmit={handleRegistration}>
                                        <div className='row'>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        name="full_name"
                                                        className="form-control"
                                                        placeholder="Full Name"
                                                        value={formData.full_name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                className="form-control"
                                                placeholder="Password Confirmation"
                                                value={formData.password_confirmation}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="tel"
                                                name="phone_no"
                                                className="form-control"
                                                placeholder="Phone Number"
                                                value={formData.phone_no}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        {isLoaded && (
                                            <Autocomplete
                                                onLoad={onLoadAutocomplete}
                                                onPlaceChanged={onPlaceChanged}
                                            >
                                                <input
                                                    type="text"
                                                    name="business_address"
                                                    className="form-control"
                                                    placeholder="Business Address"
                                                    value={formData.business_address}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Autocomplete>
                                        )}

                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                name="country"
                                                className="form-control"
                                                placeholder="Country"
                                                value={formData.country}
                                                readOnly
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                name="city"
                                                className="form-control"
                                                placeholder="City"
                                                value={formData.city}
                                                readOnly
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 form-check">
                                            <div>
                                                <input type="checkbox" className="form-check-input" id="rememberme" />
                                                <label className="form-check-label" htmlFor="rememberme">Remember me</label>
                                            </div>
                                            <div className="forgot-password">
                                                <Link to="/forgot-password">Forgot Password?</Link>
                                            </div>
                                        </div>
                                        <div className='acc-terms'>
                                            <p>By signing in, you have read and agree to our <br /><Link to="/terms-and-conditions">Printgraphi General Terms and Conditions</Link></p>
                                        </div>
                                        <div className="accbtn">
                                            <button type="submit" className="singin">Sign up</button>
                                            <Link to="/login"><button className="makeacc">Already an Account</button></Link>
                                        </div>
                                    </form>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
            </div>

            {/* Bootstrap Modal for error message */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Registration Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RegisterComponent;

import React from 'react'
import { DesignReviewBack } from '../DesignReviewComponent/DesignReviewBack'
import { DesignReviewMain } from '../DesignReviewComponent/DesignReviewPage'

export const DesignReview = () => {
  return (
    <>
    <DesignReviewBack/>
    <DesignReviewMain/>
    </>
  )
}

import React from 'react'
import { DesignEditArea } from '../UploaddesignComponents/Uploaddesign'
import { UploadingSection } from '../UploaddesignComponents/UploadYourDesignSecRow'


export const MainUploaddesign = () => {
  return (
    <>
    <DesignEditArea/>
    {/* <UploadingSection/> */}
    </>
  )
}

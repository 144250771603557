import React, { useEffect, useState } from "react";
import ProductImage from "../assets/images/prod.png";
import PaymantImage from "../assets/images/paycard.png";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import axios from "axios";
import { addToCart, fetchAttributesByType, fetchProductById, fetchProductSizeType } from "../../services/productService";
import { BASE_URL } from "../../services/Constant";

export const ProductInfo = () => {
 
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedProductType, setSelectedProductType] = useState(null);
  
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [succModalShow, setSuccModalShow] = useState(false);
  const handleCloseSucc = () => setSuccModalShow(false);
  const handleShowSucc = () => setSuccModalShow(true);

  const { pathname } = window.location;
  const id = pathname.split("/").pop();
  const [product, setProduct] = useState({});
  const [productType, setProductType] = useState({});
  const [productAttr, setProductAttr] = useState([]);
  const [attributeData, setAttributeData] = useState([]);
  const [productTypeHori, setProductTypeHori] = useState([]);
  const [productTypeVerti, setProductTypeVerti] = useState([]);
  const [cartItems, setCartItems] = useState([]); // State for cart items


  useEffect(() => {
    const fetchProductAndAttributes = async () => {
      try {
        const productData = await fetchProductById(id);
        setProduct(productData.data);
        setProductType(productData.data.type);

        const attributes = productData.data.attribute || [];
        setProductAttr(attributes);

        const allFetchedAttributes = [];
        await Promise.all(
          attributes.map(async (attr) => {
            try {
              const attributeData = await fetchAttributesByType(attr.type);
              allFetchedAttributes.push(...attributeData.data);
            } catch (err) {
              console.error("Error fetching attribute data:", err);
            }
          })
        );

        setAttributeData(allFetchedAttributes);

        // Set default selected attributes to the first attribute of each category
        const defaultSelected = {};
        attributes.forEach(attr => {
          const firstAttribute = allFetchedAttributes.find(attribute => attribute.type === attr.type);
          if (firstAttribute) {
            defaultSelected[attr.type] = firstAttribute.id;
          }
        });
        setSelectedAttributes(defaultSelected); // Set default selected attributes

      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    const fetchProductType = async () => {
      try {
        const productData = await fetchProductSizeType(product.type);
        setProductTypeHori(Array.isArray(productData.data.horizontal) ? productData.data.horizontal : [productData.data.horizontal]);
        setProductTypeVerti(Array.isArray(productData.data.vertical) ? productData.data.vertical : [productData.data.vertical]);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductType();
    fetchProductAndAttributes();
  }, [id]);

  const handleAttributeSelect = (attrType, attributeId) => {
    setSelectedAttributes(prevState => ({
      ...prevState,
      [attrType]: attributeId,
    }));
  };

  const handleProductTypeSelect = (type, productId) => {
    setSelectedProductType({ type, productId });
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === (product?.product_images?.length || 0) - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to go to the previous image
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? (product?.product_images?.length || 0) - 1 : prevIndex - 1
    );
  };
  const userToken = localStorage.getItem('userToken');
console.log(userToken)
const navigate = useNavigate();
 
const handleAddToCart = async () => {
  try {
    const userToken = localStorage.getItem('userToken');

    // If userToken is null, redirect to the login page
    if (!userToken) {
      navigate('/login');
      return; // Exit the function early
    }

    // Collect all the attribute IDs selected by the user
    const productAttributeIds = Object.values(selectedAttributes);
    const pageSizeId = selectedProductType?.productId ? [selectedProductType.productId] : [];
    const attributeIds = [...productAttributeIds, ...pageSizeId];

    // Prepare the data payload for the API call
    const data = {
      product_id: product.id,
      quantity: 1, // Update this based on your quantity logic
      attribute_id: JSON.stringify(attributeIds), // Sending attribute IDs as an array
      
    };

    // Make the API call using the updated addToCart function
    const response = await addToCart(data);
    if (response.status === "success") {
      // Extract the order ID from the response
      const orderId = response.data.id;
      
      // Redirect to the next page with the order ID
      navigate(`/MainUploadDesign/${orderId}`);
      
      // Show success message
      handleShowSucc("Added to Cart Successfully");
    } else {
      // Handle case where the response isn't successful
      handleShowSucc("Failed to add to cart");
    }
    // Show success message based on API response
    handleShowSucc("Added to Cart Successfully");

  } catch (error) {
    console.error("Error adding to cart:", error);
    handleShowSucc("Error adding to cart: " + error.message);
  }
};

  return (
    <section className="product-info">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div id="js-gallery" className="gallery">
              {/* Gallery Hero */}
              <div className="gallery__hero">
                <span className="position-absolute bottom-50" onClick={prevImage}>
                  <AiOutlineArrowLeft />
                </span>
                {product.product_images && product.product_images.length > 0 ? (
                  <img
                    src={product.product_images[currentImageIndex]}
                    alt="Product Gallery"
                    className="gallery-image"
                  />
                ) : (
                  <p>No images available</p>
                )}
                <span className="position-absolute top-50 gallery-hero-right-arr" onClick={nextImage}>
                  <AiOutlineArrowRight />
                </span>
              </div>
              {/* Gallery Thumbs */}
              <div className="gallery__thumbs">
                {product.product_images &&
                  product.product_images.map((image, index) => (
                    <Link
                      to="#"
                      data-gallery="thumb"
                      className={index === currentImageIndex ? "is-active" : ""}
                      key={index}
                      onClick={() => setCurrentImageIndex(index)}
                    >
                      <img src={image} alt={`Thumbnail ${index}`} />
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="pro_detail">
              <h2>{product.name}</h2>
              <div className="rating">
                <span className="ratin_no">5</span>
                <span className="rev">(56 reviews)</span>
              </div>
              <div className="pro-short-discription">
                <p>{product.description}</p>
              </div>
              <div className="preferences">
                <h2>Preferences</h2>
              </div>
              <div className="clientinformation">
              {productAttr.length === 0 ? (
                  <p>No attributes available</p>
                ) : (
                  productAttr.map((attr, index) => (
                    <div className="categories" key={index}>
                      <h4>{attr.name}</h4>
                      <div className="row">
                        {attributeData
                          .filter(attribute => attribute.type === attr.type)
                          .map((attribute, attrIndex) => (
                            <div className="col-lg-4" key={attrIndex}>
                              <div className="card d-flex justify-content-between align-items-center attribute">
                                <div className="box-1">
                                  <img src={`${BASE_URL}/${attribute.image}`} alt={attribute.name} />
                                </div>
                                <div className="radioBox">
                                  <div className="text-end">
                                    <input
                                      type="radio"
                                      name={`attribute-${attr.type}`}
                                      value={attribute.id}
                                      checked={selectedAttributes[attr.type] === attribute.id}
                                      onChange={() => handleAttributeSelect(attr.type, attribute.id)}
                                    />
                                  </div>
                                  <p>Price: ${attribute.price}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="price">
                <p>
                  Starting at original price: ${product.original_price} current sale price: ${product.sale_price}
                </p>
              </div>
              <div className="paycard">
                <img src={PaymantImage} alt="Payment method" className="paycard-img" />
              </div>
              <div className="prod_btn">
                <button
                  className="Click-here"
                  variant="primary"
                  onClick={handleShow}
                >
                  Upload your design
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
      show={modalShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="custom-model-wrap">
          <div className="pop-up-content-wrap">
            <div className="model-header">
              <h1>Standard Business Cards</h1>
              <h2>100 from $17.99</h2>
              <h2>Horizontal</h2>
            </div>
          </div>
          <div className="horizontalcard">
            <div className="row">
            {productTypeHori.map((productHori, index) => (
                  <div className="col-lg-6 my-4" key={index}>
                    <div className={`model-card ${selectedProductType?.productId === productHori.id && selectedProductType?.type === 'horizontal' ? 'selected' : ''}`}
                      onClick={() => handleProductTypeSelect('horizontal', productHori.id)}>
                      <div className="grey-card">
                        <div className="innerwhite p-2"><img src={`${BASE_URL}/${productHori.image}`} style={{ objectFit: 'contain', width: '100%', height: '100%' }} /></div>
                      </div>
                      <div className="model-card-heading">
                        <p>{productHori.name}</p>
                      </div>
                      <div className="model-cardprice">
                        <h3>${productHori.price}</h3>
                      </div>
                    </div>
                  </div>
                ))}

            </div>
          </div>
          <div className="vertical">
            <div className="model-header">
              <h2>Vertical</h2>
            </div>
            <div className="row">
            {productTypeVerti.map((productVerti, index) => (
                  <div className="col-lg-6" key={index}>
                    <div className={`model-card ${selectedProductType?.productId === productVerti.id && selectedProductType?.type === 'vertical' ? 'selected' : ''}`}
                      onClick={() => handleProductTypeSelect('vertical', productVerti.id)}>
                      <div className="grey-card">
                        <div className="portraitinnerwhite p-2"><img src={`${BASE_URL}/${productVerti.image}`} style={{ objectFit: 'contain', width: '100%', height: '100%' }} /></div>
                      </div>
                      <div className="model-card-heading">
                        <p>{productVerti.name}</p>
                      </div>
                      <div className="model-cardprice">
                        <h3>${productVerti.price}</h3>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="potbtn">
                  {/* <Link to="/MainUploaddesign"> */}
                  {selectedProductType?.productId ? (
                    <button className="text-white" onClick={handleAddToCart}>Potrait</button>
                  ) : (
                    <button className="bg-danger text-white">Select a size</button>
                    )}
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

    </Modal>
      <Modal
      show={succModalShow}
      onHide={handleCloseSucc}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="custom-model-wrap">
          <div className="pop-up-content-wrap">
            <div className="model-header">
              <h1>Success</h1>
              <h2>Message</h2>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </section>
  );
};

import React from 'react'
import RegisterComponent from '../LoginAndRegister/RegisterComponent'

const Register = () => {
  return (
    <>
      <RegisterComponent />
    </>
  )
}

export default Register

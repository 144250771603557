import React from 'react'
import { DesignEditArea } from '../BusinessCardProductComponent/DesignEditArea'
import { UploadingSection } from '../BusinessCardProductComponent/UploadingSection'

const BusinessCardProduct = () => {
  return (
    <>
      {/* <DesignEditArea /> */}
      <UploadingSection />
    </>
  )
}

export default BusinessCardProduct

import React from 'react';
import { Link } from "react-router-dom";
import footerLogo from '../assets/images/footerlogo.png'; // Import the footer logo image

export const FooterMainSection = () => {
  return (
    <section id="footer-main">
      <div className="container">
        <div className="row">
          <div className="col-3 col-12-medium">
            <img className="footerlogo" src={footerLogo} alt="Footer Logo" />
            <p className="aboutfooter">Our paper is sourced from FSC-certified mills. We plant enough trees to more than double our paper usage.</p>
            <h6>©2024 PRINTGRAPHY All Rights Reserved</h6>
          </div>
          <div className="col-3 col-12-medium">
            <div className="footerlinks">
              <h4>useful LINKS</h4>
              <ul>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">Deals</Link></li>
                <li><Link to="#">Business cards</Link></li>
                <li><Link to="#">Post cards</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-3 col-12-medium">
            <div className="footerlinks">
              <h4>explore more</h4>
              <ul>
                <li><Link to="#">About Us</Link></li>
                <li><Link to="#">Contact</Link></li>
                <li><Link to="#">FAQs</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-3 col-12-medium">
            <div className="footerlinks">
              <h4>Contact</h4>
              <ul className='linksEmail'>
                <li><Link to="#"><i className="fa fa-envelope-o" aria-hidden="true"></i> support@printgraphi.com</Link></li>
                <li><Link to="#"><i className="fa fa-phone" aria-hidden="true"></i> +1 532323232</Link></li>
                <li><Link to="#"><i className="fa fa-map" aria-hidden="true"></i> Loremipsum 78227 Ny 29922</Link></li>
              </ul>
              <ul className="footersociallinks">
                <li><Link to="#"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                <li><Link to="#"><i className="fa fa-instagram" aria-hidden="true"></i></Link></li>
                <li><Link to="#"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
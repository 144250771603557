// src/services/productService.js
import apiClient from './apiService';

// Fetch product details by ID
export const fetchProductsByCategoryId = async (categoryId) => {
    try {
      const response = await apiClient.get('/category_Product', {
        params: { category_id: categoryId },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching products by category ID:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };
export const fetchTempletesBySubCategoryId = async (categoryId) => {
    try {
      const response = await apiClient.get('/user/templete', {
        params: { sub_category_id: categoryId },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching products by category ID:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };
export const fetchCategory = async () => {
    try {
      const response = await apiClient.get('/category');
      return response.data.data;
    } catch (error) {
      console.error("Error fetching products by category ID:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };


import React, { useState } from "react";
import ProductImage from "../assets/images/prod.png";
import PaymantImage from "../assets/images/paycard.png";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form } from 'react-bootstrap';
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import Button from 'react-bootstrap/Button';

function MyVerticallyCenteredModal(props) {
  const [isSlider, setIsSlider] = useState(1);

  // select color


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="custom-model-wrap">
          <div className="pop-up-content-wrap">
            <div className="">
              <h1>Glossy Business Cards</h1>
              <h2>100 from $13.99</h2>
              <h2>Card Corners</h2>
            </div>
          </div>
          <div className="horizontalcard">
            <div className="row">
              <div className="col-lg-6">
                <Link to="#">
                  <div className="model-card">
                    <div className="grey-card">
                      <div className="innerwhite sqaureinnerwhite-002"></div>
                    </div>
                    <div className="model-card-heading">
                      <p>Standard</p>
                    </div>
                    <div className="model-cardprice-bcpd">
                      <h3> $00.00</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6">
                <Link to="#">
                  <div className="model-card">
                    <div className="grey-card">
                      <div className="sqaureinnerwhite sqaureinnerwhite-001"></div>
                    </div>
                    <div className="model-card-heading">
                      <p>Rounded</p>
                    </div>
                    <div className="model-cardprice">
                      <h3> $17.99</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="vertical">
            <div className="">
              <h2>Select Color</h2>
            </div>
            <div className="row">
            
            </div>
          </div>
          <div className="vertical">
            <div className="">
              <h2>Card Texture</h2>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-4">
                <Link to="#">
                  <div className="model-card">
                    <div className="grey-card">
                      <img src={require("../assets/images/businesscard/Rectangle_21460.png")} alt="img" style={{width: "100%"}} />
                      {/* <div className="portraitinnerwhite"></div> */}
                    </div>
                    <div className="model-card-heading">
                      <p>No Finish</p>
                    </div>
                    <div className="model-cardprice-bcpd">
                      <h3> $00.00</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 mt-4">
                <Link to="#">
                  <div className="model-card">
                    <div className="grey-card">
                      <img src={require("../assets/images/businesscard/Rectangle_21461.png")} alt="img" style={{width: "100%"}} />
                      {/* <div className="portraitinnerwhite"></div> */}
                    </div>
                    <div className="model-card-heading">
                      <p>Embossed Gloss</p>
                    </div>
                    <div className="model-cardprice">
                      <h3> $01.99</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 mt-4">
                <Link to="#">
                  <div className="model-card">
                    <div className="grey-card">
                      <img src={require("../assets/images/businesscard/Rectangle_21462.png")} alt="img" style={{width: "100%"}} />
                      {/* <div className="portraitinnerwhite"></div> */}
                    </div>
                    <div className="model-card-heading">
                      <p>Matte</p>
                    </div>
                    <div className="model-cardprice-bcpd">
                      <h3> $01.99</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="potbtn">
                  <Link to="/business-card-detail"> 
                    <button>Edit This Design</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export const ProductInfo = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className="product-info">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div id="js-gallery" className="gallery">
              {/* Gallery Hero */}
              <div className="gallery__hero">
                <span className="position-absolute bottom-50">
                  <AiOutlineArrowLeft />
                </span>
                <img src={ProductImage} alt="Alt text goes here" />
                <span className="position-absolute top-50 right-6">
                  <AiOutlineArrowRight />
                </span>
              </div>
              {/* Gallery Hero */}
              {/* Gallery Thumbs */}
              <div className="gallery__thumbs">
                {/* Repeat for other thumbnails */}
                <Link
                  to="{ProductImage}"
                  data-gallery="thumb"
                  className="is-active"
                >
                  <img src={ProductImage} alt="Alt text goes here" />
                </Link>

                <Link
                  to="{ProductImage}"
                  data-gallery="thumb"
                  className="is-active"
                >
                  <img src={ProductImage} alt="Alt text goes here" />
                </Link>

                <Link
                  to="{ProductImage}"
                  data-gallery="thumb"
                  className="is-active"
                >
                  <img src={ProductImage} alt="Alt text goes here" />
                </Link>

                <Link
                  to="{ProductImage}"
                  data-gallery="thumb"
                  className="is-active"
                >
                  <img src={ProductImage} alt="Alt text goes here" />
                </Link>

                <Link
                  to="{ProductImage}"
                  data-gallery="thumb"
                  className="is-active"
                >
                  <img src={ProductImage} alt="Alt text goes here" />
                </Link>

                <Link
                  to="assets/images/prod.png"
                  data-gallery="thumb"
                  className="is-active"
                >
                  <img src={ProductImage} alt="Alt text goes here" />
                </Link>
              </div>

              {/* Gallery Thumbs */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pro_detail">
              <h2>Standard Business Cards</h2>
              <div className="rating">
                {/* Rating stars */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-star-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
                {/* Repeat for other stars */}
                <span className="ratin_no">5</span>
                <span className="rev">(56 reviews)</span>
              </div>
              <div className="pro-short-discription">
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>
              </div>
              <div className="preferences">
                <h2>Preferences</h2>
              </div>
              <div className="clientinformation">
                {/* Input fields */}
                <input type="text" placeholder="paper thickness" id="field2" />
                <input
                  type="text"
                  placeholder="Select by Cards Textures"
                  id="field2"
                />
                <input type="text" placeholder="Thickness" id="field2" />
                <input
                  type="text"
                  placeholder="Standard Card template"
                  id="field2"
                />
                <input
                  type="text"
                  placeholder="Standard Card template"
                  id="field2"
                />
                {/* Repeat for other input fields */}
              </div>
              <div className="price">
                <p>
                  100 starting at original price:$17.99 current sale
                  price:$14.39
                </p>
              </div>
              <div className="paycard">
                <img src={PaymantImage} alt="Alt text goes here" />
              </div>
              <div className="prod_btn">
                <Link to="/business-card-detail">
                  <button style={{ background: "#1D90CF", color: "#fff" }}>
                    Use Template
                  </button>
                </Link>
                <button
                  className="Click-here"
                  variant="primary"
                  onClick={() => setModalShow(true)}
                >
                  Upload your design
                </button>

                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                {/* Include PHP code for product popup */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

import React, { useState } from 'react';

const PersonalForm = ({ formData, handleChange, handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First name" />
                </div>
                <div className="col-md-6">
                    <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last name" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <input type="text" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile" required />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <select id="city" name="city" value={formData.city} onChange={handleChange}>
                        <option value="">Select City</option>
                        <option value="New York">New York</option>
                        <option value="Los Angeles">Los Angeles</option>
                        <option value="Chicago">Chicago</option>
                        {/* Add more cities as needed */}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email address" />
                </div>
            </div>
            <ChangePasswordForm />
        </form>
    );
};

const ChangePasswordForm = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Check if new password matches confirm password
      if (newPassword !== confirmPassword) {
        setError("New password and confirm password don't match");
        return;
      }
  
      // Here you can add your logic to handle password change, such as sending a request to the server
  
      // Reset form fields
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setError('');
    };

    return (
        <div className="changepassword">
            <h2>Change Password</h2>
            <p>Create A new Password or modify a existing one</p>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="oldPassword">Old Password:</label>
                    <input
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="newPassword">New Password:</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="confirmPassword">Confirm New Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="cngpasbtn">
                    <button type="submit">Change Password</button>
                </div>
            </form>
        </div>
    );
};

export default PersonalForm;

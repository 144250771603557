import React, { useState, useEffect } from "react";
import StepImage1 from "../assets/images/20943863_1.png";
import StepImage2 from "../assets/images/20943863_2.png";
import StepImage3 from "../assets/images/20943863_3.png";
import StepImage4 from "../assets/images/20943863_4.png";
import StepImage5 from "../assets/images/20943863_5.png";

const Main = () => {
  const [activeStep, setActiveStep] = useState(0); // Set the initial active step

  const images = [StepImage1, StepImage2, StepImage3, StepImage4, StepImage5];
  const orderProcess = [
    {
      id: 100,
      img: StepImage1,
      text: "Relax, we are processing your order"
    },
    {
      id: 200,
      img: StepImage2,
      text: "Your Order is in the Designing Process"
    },
    {
      id: 300,
      img: StepImage3,
      text: "Your Order has been Shipped"
    },
    {
      id: 400,
      img: StepImage4,
      text: "Your Order in Route"
    },
    {
      id: 500,
      text: StepImage5,
      text: "Your Order has Arrived"
    }
  ]

  // Function to handle step change
  const handleStepChange = (index) => {
    setActiveStep(index);
  };

  // Effect to automatically change step every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % orderProcess.length);
    }, 3000); // Change step every 5000 milliseconds (5 seconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <>
      <div className="mainporder-tracking mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div>
                <img
                  src={images[activeStep]}
                  alt="order-img"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column align-items-start">
                <h4 className="m-0 fw-bolder">Expected Arrival</h4>
                <p>01/06/2024</p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <h4 className="fw-bolder order-prcess-text">
              {orderProcess[activeStep].text}
            </h4>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 d-flex justify-content-center">
              {/* Custom Stepper with Main Line */}
              <div className="custom-stepper">
                {images.map((image, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`step ${activeStep === index ? "active" : ""}`}
                      onClick={() => handleStepChange(index)}
                    >
                      {index + 1}
                    </div>
                    {index !== images.length - 1 && (
                      <div className="main-line"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;

import React from 'react';
import { Link } from "react-router-dom";

export const Breadcrumbs = () => {
  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <section id='slidebread' className="breadcrumbs">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link to="#" onClick={goBack}><i className="fa fa-angle-left" aria-hidden="true"></i><span>Home / Standard Business Card</span></Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="standardline">
                <hr />
                </div>
            </div>
        </div>
      </div>
      
    </section>
  );
};
import React from 'react';
import { Link } from "react-router-dom";
import callimages from '../../../assets/images/callimages.png';

export const CallToActionSection = () => {
  return (
    <section id="calltoaction">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-12-medium">
            <h1>Design your complimentary logo with ease in just a matter of minutes.</h1>
            <Link to="#"><button>Get Started</button></Link>
          </div>
          <div className="col-6 col-12-medium">
            <div className="img-call">
            <img src={callimages} alt="Alt text goes here" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
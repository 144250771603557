import React from 'react'
import { BusinessExlporeHeader } from '../BusinessCardExploreComponent/BusinessCardExploreHeader'
import { BusinessCardExploreProduct } from '../BusinessCardExploreComponent/BusinessCardExploreProduct'

export const BusinessCardExplore = () => {
  return (
    <>
    <BusinessExlporeHeader/>
    <BusinessCardExploreProduct/>
    </>
  )
}
import React from 'react'
import Main from '../CategoryBusinessCardComponent/Main'

const CategoryBusinessCard = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default CategoryBusinessCard

import React from 'react'
import { BannerSection } from '../HomeComponent/Banner/Banner.js/Banner'
import { CategoriesSection } from '../HomeComponent/Banner/Banner.js/Category'
import { MainCategoriesSection } from '../HomeComponent/Banner/Banner.js/Secondcategory'
import { CallToActionSection } from '../HomeComponent/Banner/Banner.js/Calltoaction'
import { GallerySection } from '../HomeComponent/Banner/Banner.js/Gallery'
import { AboutSection } from '../HomeComponent/Banner/Banner.js/About'
import { NewCallSection } from '../HomeComponent/Banner/Banner.js/Calltoactionfooter'

export const Home = () => {
  return (
    <>
      <BannerSection/>
      <CategoriesSection/>
      <MainCategoriesSection/>
      <CallToActionSection/>
      <GallerySection/>
      <AboutSection/>
      <NewCallSection/>
    </>
  )
}

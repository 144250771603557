import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import OTPInput from "react-otp-input";

const OtpComponent = () => {
  const { pathname } = window.location;
  const email = pathname.split("/").pop(); // Extract email from URL
  console.log(email);

  const [otp, setOtp] = useState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "" });
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendCountdown, setResendCountdown] = useState(60); // Initial countdown value
  const [formData, setFormData] = useState({
    otp: "",
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    let countdownInterval;

    if (resendCountdown > 0) {
      countdownInterval = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [resendCountdown]);

  // Refs for the OTP inputs
  const otpInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // Show error popup with a specific message
  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  // Close popup
  const handleClose = () => setShowPopup(false);

  // Combine the OTP digits and send the request
  const handleVerify = async (event) => {
    event.preventDefault();

    // const otpCode = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}`;
    try {
      // API call to verify OTP
      const response = await axios.post(
        "https://printgraphibackend.testingwebsitelink.com/api/otp_varify",
        {
          email: email, // Use the extracted email
          otp: formData.otp, // Send combined OTP
        }
      );

      console.log("OTP Verification response:", response.data);

      // Navigate to another page upon successful verification (if needed)
      navigate("/login");
    } catch (error) {
      // Display error message in popup
      if (error.response && error.response.data && error.response.data.message) {
        handleShow(error.response.data.message);
      } else {
        handleShow("An error occurred. Please try again.");
      }
    }
  };

  const handleResend = async (event) => {
    event.preventDefault();

    try {
      // API call to resend OTP
      const response = await axios.post(
        "https://printgraphibackend.testingwebsitelink.com/api/resend_otp",
        {
          email: email, // Use the extracted email
        }
      );

      console.log("OTP Resend response:", response.data);
      setResendCountdown(60); // Reset the countdown after resending the OTP
    } catch (error) {
      // Display error message in popup
      if (error.response && error.response.data && error.response.data.message) {
        handleShow(error.response.data.message);
      } else {
        handleShow("An error occurred. Please try again.");
      }
    }
  };

  // Handle input changes and focus to the next input
  const handleChange = (otp) => {
    setFormData({
      ...formData,
      otp, // Update the `otp` field directly
    });
  };
  // Handle backspace and navigate to the previous input
  // const handleKeyDown = (e, index) => {
  //   // Check if the key pressed is backspace
  //   if (e.key === "Backspace" && otp[`otp${index + 1}`] === "") {
  //     // Move to the previous input if it's not the first input
  //     if (index > 0) {
  //       otpInputRefs[index - 1].current.focus();
  //     }
  //   }
  // };

  const formatCountdownTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <div className="register-bg-img">
        <div className="container">
          <div className="row">
            <div className="col-3"></div>
            <div className="col-lg-6 col-sm-12">
              <div className="accconec login-bg-color">
                <div className="account-body">
                  <h5 className="acc-title">Verify your email.</h5>
                  <form onSubmit={handleVerify}>
                    <div className="d-flex otpinputs">
                    <OTPInput
              //  name="otp"
              name="otp"
               id="otp"
               value={formData.otp}
               onChange={(e) => handleChange(e)}
      numInputs={5}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
                      {/* {["otp1", "otp2", "otp3", "otp4", "otp5"].map((otpName, index) => (
                        <div className="m-3" key={otpName}>
                          <input
                            type="number"
                            className="form-control"
                            name={otpName}
                            value={otp[otpName]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={otpInputRefs[index]}
                            required
                          />
                        </div>
                      ))} */}
                    </div>
                    <div className="mb-3 form-check">
                      <div>
                        {resendCountdown > 0
                          ? `Resend code in ${formatCountdownTime(resendCountdown)}`
                          : "Didn't receive the code?"}
                      </div>
                      <div className="forgot-password">
                        <Link
                          onClick={handleResend}
                          style={{
                            pointerEvents: resendCountdown > 0 ? "none" : "auto",
                            color: resendCountdown > 0 ? "gray" : "blue",
                          }}
                        >
                          Resend email
                        </Link>
                      </div>
                    </div>
                    <div className="accbtn">
                      <button type="submit" className="singin">
                        Verify
                      </button>
                      <Link to="/login">
                        <button className="makeacc">Already have an account</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>
        {/* Bootstrap modal for error messages */}
        {showPopup && (
        <div
          className="modal fade show error"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Error</h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleClose}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>{errorMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtpComponent;

import React from 'react';
import { Main } from '../MainCategoryCarosel/Main';


export const BusinessCardsslider = () => {
  
  
  return (
    <section className='catsli' id="categories">
      <div className="container">
        <Main/>
      </div>
    </section>
  );
};
import React from 'react'
import LoginComponent from '../LoginAndRegister/LoginComponent'

const Login = () => {
  return (
    <>
      <LoginComponent />
    </>
  )
}

export default Login

import React from 'react';
import { Link } from 'react-router-dom';
import HelpImg from '../assets/images/help.png'

export const ExlporeHeader = () => {
    return (
      <section className="exlporeheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
                <div className='exheading'><h1>Explore our all Business Cards</h1></div>
            </div>
            <div className="col-lg-6">
                <div className="helpimgg"><Link to={'#'}><img src={HelpImg} alt="Basic" /></Link></div>
            </div>
          </div>
        </div>
      </section>
    );
  };
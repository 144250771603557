import React from 'react'
import OtpComponent from '../OtpComponent/OtpComponent'

const Otp = () => {
  return (
    <>
      <OtpComponent />
    </>
  )
}

export default Otp

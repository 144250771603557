import React from 'react'
import { ShippingDetailsPage } from '../ShippingDetailsComponent/ShippingDetailsPage'

export const  Shipping = () => {
  return (
    <>
    <ShippingDetailsPage/>
    </>
  )
}

import React from 'react'
import { StandardBusinessCard } from '../StandardCardComponent/StandardBusinessCard'
import { BusinessCardsslider } from '../StandardCardComponent/Categoeyslider'
import { Breadcrumbs } from '../StandardCardComponent/back'

export const StandardMain = () => {
  return (
    <>
     <Breadcrumbs/>
    <BusinessCardsslider/>
    <StandardBusinessCard/>
    </>
    
  )
}

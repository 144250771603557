import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cardFront from '../assets/images/businesscard/business-card-back.jpg';
import cardBack from '../assets/images/businesscard/b-card-back.jpg';

export const DesignReviewMain = () => {
  const [showFront, setShowFront] = useState(true);

  const toggleImage = () => {
    setShowFront(!showFront);
  };

  return (
    <section className="designreviewmain">
      <div className="container">
        <div className="row" style={{gap: "20px 0"}}>
          <div className="col-md-6">
            <div id="frontback" className="designreviewmain-img-btn-sec-m-div">
              <div className='designreviewmain-img-prv-sec'>
                <img src={showFront ? cardFront : cardBack} alt='img' />
              </div>
              <div className='designreviewmain-btn-chng-sec'>
                <button className={showFront ? 'active' : ''} onClick={() => setShowFront(true)}>Front</button>
                <button className={!showFront ? 'active' : ''} onClick={() => setShowFront(false)}>Back</button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="designreviewcontent">
              <h1>Design Review</h1>
              <h2>All set? Let’s double check:</h2>
              <ul>
                <li>Text: Is it clear and legible?</li>
                <li>Margin: Is everything within the space?</li>
                <li>Info: Everything accurate? Spelled correctly?</li>
                <li>Images: Is everything clear (no blur)?</li>
              </ul>
              <div className="designcomment">
                <button className='designcomment-btn-sec'>edit design</button>
              </div>
              <div className="commark">
                <input type="checkbox" id="mark" />
                <label htmlFor="mark">I've reviewed and approved my design.</label>
                <div className="commbtin">
                  <Link to="/Preferences"><button>Finish</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesignReviewMain;

import React from "react";
import ThankYou from '../assets/images/thank.png';
import { Link } from 'react-router-dom';

export const ThankYouPage = () => {
    const quantity = 1;
    return (
        <div className="thankyou">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-sm-12">
                        <div className="thankyoucontent">
                            <div className="thkimg">
                                <img src={ThankYou} alt="Thankyou" />
                            </div>
                            <h1>Thank you for your order</h1>
                            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. </p>
                            <div className="clientDetail">
                            <h1>Shipping Details</h1>
                            <ul>
                                <li>John Doe City</li>
                                <li>Center St 7 , Lane 6 , House no. 10</li>
                                <li>Adams County, Alabama 99344</li>
                                <li>United States of America</li>
                                <li>808-256-2563</li>
                            </ul>
                        </div> 
                        </div>
                         
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <div className="OrderDetails">
                            <h1>Order Details</h1>
                        </div>
                        <div className="carttotal">
                            <h1>Order Summary</h1>
                            <div className="carttotalcontent">
                                <div className="row">
                                    <div className="col-6">
                                        <h2>Subtotal</h2>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>${(quantity * 20).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Shipping</p>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>Free</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>Taxes</p>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>${(quantity * 20 * 0.1).toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="carttotalcontentbottom">
                                            <h1>Order Total</h1>
                                        </div>
                                    </div>
                                    <div className="col-6 alignleft">
                                        <p>${(quantity * 20 * 1.1).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="trackorderbtn">
                           <p className="track"><Link to="/order-tracking"><button>Track Order</button></Link></p> 
                           <p className="feedbck"><Link to=""><button>give us a Feedback</button></Link></p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
